import React from "react";

import { generateRandomString } from "../lib/generate";

type Alert = {
  severity: "info" | "warning" | "error";
  message: string;
  key: string;
  fading: boolean;
};

const defaultUiState = {
  alerts: new Array<Alert>(),
  currentOverlay: "",
  assetsPanelOpen: true,
  hoveredObject: "",
  messagesPanelOpen: true,
  setAssetsPanelOpen: (open: boolean) => {},
  setHoveredObject: (objectId: string) => {},
  setMessagesPanelOpen: (open: boolean) => {},
  addAlerts: (
    alerts: Omit<Alert, "key" | "fading">[],
    persistMs?: number
  ) => {},
  setOverlay: (overlay: string) => {},
};

const UIContext = React.createContext<typeof defaultUiState | null>(null);

export const UIProvider = ({ children }: { children: React.ReactNode }) => {
  const [alerts, setAlerts] = React.useState(
    defaultUiState.alerts as (Alert & { expiresAt: number })[]
  );
  const [currentOverlay, setCurrentOverlay] = React.useState(
    defaultUiState.currentOverlay
  );
  const [assetsPanelOpen, setAssetsPanelOpen] = React.useState(
    defaultUiState.assetsPanelOpen
  );
  const [messagesPanelOpen, setMessagesPanelOpen] = React.useState(
    defaultUiState.messagesPanelOpen
  );
  const [hoveredObject, setHoveredObject] = React.useState(
    defaultUiState.hoveredObject
  );

  const alertsVisible = alerts.length > 0;

  React.useEffect(() => {
    if (!alertsVisible) {
      return;
    }
    const interval = setInterval(() => {
      const now = Date.now();

      setAlerts((prevAlerts) =>
        prevAlerts
          .filter((alert) => alert.expiresAt > now)
          .map((alert) => ({
            ...alert,
            fading: alert.expiresAt - now < 5000,
          }))
      );
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [alertsVisible]);

  const value = React.useMemo(
    () => ({
      alerts,
      currentOverlay,
      assetsPanelOpen,
      hoveredObject,
      messagesPanelOpen,
      setAssetsPanelOpen,
      setHoveredObject,
      setMessagesPanelOpen,
      addAlerts: (
        newAlerts: Omit<Alert, "key" | "fading">[],
        persistMs = 5000
      ) => {
        const now = Date.now();

        setAlerts((prevAlerts) => [
          ...prevAlerts,
          ...newAlerts.map((alert) => ({
            ...alert,
            expiresAt: now + persistMs,
            key: generateRandomString(),
            fading: false,
          })),
        ]);
      },
      setOverlay: setCurrentOverlay,
    }),
    [
      alerts,
      currentOverlay,
      assetsPanelOpen,
      hoveredObject,
      messagesPanelOpen,
      setAssetsPanelOpen,
      setHoveredObject,
      setMessagesPanelOpen,
      setAlerts,
      setCurrentOverlay,
    ]
  );

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
};

export const useUI = () => {
  const uiContext = React.useContext(UIContext);

  if (!uiContext) {
    throw new Error("useUI must be used inside the UIProvider");
  }
  return uiContext;
};
