import React from "react";
import { useSearchParams } from "react-router-dom";

export const useSearchParam = (paramName: string) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setParamValue = (paramValue: string) => {
    setSearchParams((prevSearchParams) => {
      if (paramValue === "") {
        prevSearchParams.delete(paramName);
      } else {
        prevSearchParams.set(paramName, paramValue);
      }
      return prevSearchParams;
    });
  };

  return [searchParams.get(paramName) ?? "", setParamValue] as const;
};

export const useTimer = (interval: number, callback: () => void) => {
  const [paused, setPaused] = React.useState(true);

  React.useEffect(() => {
    if (paused) return;

    let expected = Date.now() + interval;
    const step = () => {
      const dt = Date.now() - expected;

      expected += interval;
      timeout = setTimeout(step, Math.max(0, interval - dt));
    };
    let timeout = setTimeout(step, interval);

    return () => {
      clearTimeout(timeout);
    };
  }, [interval, paused]);

  return {
    paused,
    pause: () => setPaused(true),
    play: () => setPaused(false),
  };
};
