import { LngLatBounds } from "mapbox-gl";
import React from "react";
import { useSearchParams, Link } from "react-router-dom";

import { canAccessService } from "../../lib/auth";
import { epochToDate } from "../../lib/formatting";
import { getDeviceTypeLabel } from "../../lib/v2x/v2xUtils";
import { useAuth } from "../../state/authState";
import { useMap } from "../../state/mapState";
import { useUI } from "../../state/uiState";

import SiteCard from "./SiteCard";
import { PanelCardField } from "../PanelCard";
import Input from "../controls/Input";

import ArrowIcon from "../../assets/icons/arrow.svg";
import CornersIcon from "../../assets/icons/corners.svg";
import CornersCircleIcon from "../../assets/icons/cornersCircle.svg";

import { serviceBasePaths } from "../../config";

import { FixedDevice, NormalizedSite } from "../../lib/devices/deviceTypes";
import { Position } from "../../lib/json";

const renderDeviceFields = (
  device: FixedDevice,
  site: NormalizedSite | null
) => {
  if (device.deviceType === "RSU") {
    return (
      <>
        <div>
          <PanelCardField value={getDeviceTypeLabel(device.deviceType)} />
        </div>
        <div>
          <PanelCardField label="IP Address" value={device.ipAddress} />
          <PanelCardField
            label="Make & Model"
            value={
              device.make
                ? device.model
                  ? `${device.make} ${device.model}`
                  : device.make
                : "-"
            }
          />
        </div>
        <div>
          <PanelCardField label="Latitude" value={site?.lat} />
          <PanelCardField label="Longitude" value={site?.lon} />
        </div>
        <div>
          <PanelCardField
            label="Last Updated"
            value={epochToDate(device.changedAt, false)}
          />
          <PanelCardField label="Serial Number" value={device.serialNumber} />
        </div>
      </>
    );
  }
  return (
    <>
      <div>
        <PanelCardField value={getDeviceTypeLabel(device.deviceType)} />
      </div>
      <div>
        <PanelCardField label="IP Address" value={device.ipAddress} />
        <PanelCardField
          label="Make & Model"
          value={
            device.make
              ? device.model
                ? `${device.make} ${device.model}`
                : device.make
              : "-"
          }
        />
      </div>
    </>
  );
};

const AssetsPanel = ({
  currentSite,
  devices,
  focusCoordinates,
  sites,
}: {
  currentSite: NormalizedSite | null;
  devices: { [deviceId: string]: FixedDevice };
  focusCoordinates: string;
  sites: NormalizedSite[];
}) => {
  const { currentUser, getDebug } = useAuth();
  const { mapRef } = useMap();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    assetsPanelOpen,
    hoveredObject,
    setAssetsPanelOpen,
    setHoveredObject,
  } = useUI();

  const search = searchParams.get("search") ?? "";
  const source = searchParams.get("source") ?? "";

  const setSearch = (newSearch: string) => {
    setSearchParams((prevSearchParams) => {
      if (newSearch === "") {
        prevSearchParams.delete("search");
      } else {
        prevSearchParams.set("search", newSearch);
      }
      return prevSearchParams;
    });
  };

  const validSources = sites.map((site) => site.id).join("|");

  const setSource = React.useCallback(
    (newSource: string) => {
      setSearchParams((prevSearchParams) => {
        if (newSource === "" || !validSources.split("|").includes(newSource)) {
          prevSearchParams.delete("source");
        } else {
          prevSearchParams.set("source", newSource);
        }
        prevSearchParams.delete("from");
        prevSearchParams.delete("scrub");
        prevSearchParams.delete("search");

        return prevSearchParams;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validSources]
  );

  const results = sites.filter(
    (site) =>
      site.intersectionId?.toString().includes(search.toLowerCase()) ||
      site.id?.toLowerCase().includes(search.toLowerCase()) ||
      site.name.toLowerCase().includes(search.toLowerCase()) ||
      site.type.toLowerCase().includes(search.toLowerCase()) ||
      site.lon.toString().includes(search) ||
      site.lat.toString().includes(search) ||
      site.deviceIds.find((deviceId) => {
        const device = devices[deviceId];

        return (
          device.id.toLowerCase().includes(search.toLowerCase()) ||
          (device.ipAddress ?? "").includes(search.toLowerCase()) ||
          device.make?.toLowerCase().includes(search.toLowerCase()) ||
          device.model?.toLowerCase().includes(search.toLowerCase()) ||
          device.serialNumber?.toLowerCase().includes(search.toLowerCase())
        );
      })
  );

  const flyHome = React.useCallback(() => {
    if (!(mapRef as any)?.current) return;

    const coordinates = focusCoordinates
      .split("|")
      .map(
        (coords) =>
          coords.split(",").map((part) => parseFloat(part)) as Position
      );
    const bounds = new LngLatBounds(
      coordinates[0] as any,
      coordinates[0] as any
    );

    for (let i = 1; i < coordinates.length; i += 1) {
      bounds.extend(coordinates[i] as any);
    }
    mapRef.current.fitBounds(bounds, {
      maxZoom: coordinates.length < 2 ? 18 : 15,
      padding: 40,
    });
  }, [focusCoordinates, mapRef]);

  return (
    <div className="panel">
      {assetsPanelOpen ? (
        <div className="panel-inner">
          {source ? (
            <>
              <div className="panel-header">
                <div className="panel-ribbon">
                  <h3>
                    <span className="breadcrumb" onClick={() => setSource("")}>
                      Assets List /{" "}
                    </span>
                    Asset Details
                  </h3>
                  {currentSite ? (
                    <button
                      onClick={flyHome}
                      title="Focus on asset"
                      style={{ margin: "-4px -8px 0 0" }}
                    >
                      <img
                        src={CornersCircleIcon}
                        alt="Focus on asset"
                        width={16.8}
                        height={16.8}
                      />
                    </button>
                  ) : null}
                </div>
                {currentSite ? (
                  <>
                    <SiteCard
                      focused
                      inert
                      site={currentSite as NormalizedSite}
                      style={{ marginBottom: 0 }}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        height: 28,
                        margin: "4px -8px 4px 0",
                        fontSize: "14px",
                        lineHeight: "20px",
                      }}
                    >
                      <span>Devices at this Site</span>
                      {canAccessService(
                        currentUser.authMetadata,
                        "web_client:device_management"
                      ) && getDebug() === "enabled" ? (
                        <Link
                          to={`${
                            serviceBasePaths["web_client:device_management"]
                          }?site=${encodeURIComponent(currentSite?.id)}`}
                        >
                          See in Device Manager
                        </Link>
                      ) : null}
                    </div>
                  </>
                ) : (
                  <div
                    style={{
                      marginBottom: "4px",
                      fontSize: "14px",
                      lineHeight: "20px",
                    }}
                  >
                    <span>Asset not found</span>
                  </div>
                )}
                <div className="horizontal-line" />
              </div>
              <div className="panel-list">
                {currentSite?.deviceIds
                  .map((deviceId) => devices[deviceId])
                  .sort((deviceA, deviceB) => {
                    if (
                      deviceA.changedAt === null ||
                      deviceB.changedAt === null
                    ) {
                      return deviceA.changedAt === deviceB.changedAt
                        ? 0
                        : deviceA.changedAt === null
                        ? 1
                        : -1;
                    }
                    return deviceA.changedAt < deviceB.changedAt ? 1 : -1;
                  })
                  .map((device) => (
                    <div key={device.id} className="panel-card compact inert">
                      {renderDeviceFields(device, currentSite)}
                    </div>
                  ))}
              </div>
            </>
          ) : (
            <>
              <div className="panel-header">
                <div className="panel-ribbon">
                  <h3>Assets List</h3>
                  <button
                    onClick={flyHome}
                    title="Focus on tenant"
                    style={{ margin: "-4px -8px 0 0" }}
                  >
                    <img
                      src={CornersIcon}
                      alt="Focus on tenant"
                      width={16.8}
                      height={16.8}
                    />
                  </button>
                </div>
                <Input
                  autoFocus
                  className={results.length ? undefined : "error"}
                  placeholder="Search by Site Name, Intersection ID, etc."
                  type="search"
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                />
                <div
                  style={{
                    margin: "4px 0",
                    fontSize: "14px",
                    lineHeight: "20px",
                  }}
                >
                  {results.length} Site{results.length === 1 ? "" : "s"}
                </div>
                <div className="horizontal-line" />
              </div>
              <div className="panel-list">
                {results.map((site) => (
                  <SiteCard
                    key={site.id}
                    hovered={hoveredObject === site.id}
                    site={site}
                    onClick={() => setSource(site.id)}
                    onMouseEnter={() => setHoveredObject(site.id)}
                    onMouseLeave={() => setHoveredObject("")}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      ) : null}
      <div
        className="panel-handle"
        onClick={() => setAssetsPanelOpen(!assetsPanelOpen)}
        tabIndex={0}
        title={`${assetsPanelOpen ? "Close" : "Open"} Assets panel`}
      >
        <div>
          <img
            src={ArrowIcon}
            alt="Toggle panel visibility"
            width={8}
            height={16}
            style={{ transform: `rotate(${assetsPanelOpen ? 0 : 180}deg)` }}
          />
        </div>
      </div>
    </div>
  );
};
export default AssetsPanel;
