import React from "react";

const Button = (
  props: React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
) => {
  const { disabled, title: providedTitle, ...rest } = props;
  let title = providedTitle;

  if (disabled) {
    if (typeof providedTitle === "string") {
      title += " (disabled)";
    } else {
      title = "(disabled)";
    }
  }
  return (
    <button disabled={disabled} title={title} {...rest}>
      {props.children}
    </button>
  );
};
export default Button;
