import React from "react";

const CardFieldset = ({
  fields,
  legend,
}: {
  fields: { label: string; value: React.ReactNode }[][];
  legend: string;
}) => (
  <div style={{ marginBottom: 64 }}>
    <p style={{ marginBottom: 16, fontSize: "20px" }}>{legend}</p>
    <div
      style={{
        backgroundColor: "#1e1e1e",
        borderRadius: 8,
        padding: "24px 24px 0 24px",
      }}
    >
      {fields.map((row) => (
        <div key={row[0].label} style={{ display: "flex", paddingBottom: 24 }}>
          {row.map((col) => (
            <div
              key={col.label}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "left",
                width: `${(1 / row.length) * 100}%`,
              }}
            >
              <div style={{ fontSize: "16px", opacity: 0.7 }}>{col.label}</div>
              <div style={{ minHeight: 24, marginTop: 8, lineHeight: "24px" }}>
                {col.value}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  </div>
);
export default CardFieldset;
