import { Bezier } from "bezier-js";
import { Map as MapType } from "mapbox-gl";
import distance from "@turf/distance";
import transformTranslate from "@turf/transform-translate";

import { Position } from "./json";

export const EARTH_RADIUS_M = 6371009;

export const bezierPointToPos = ({
  x,
  y,
  z,
}: {
  x: number;
  y: number;
  z?: number;
}): Position => [x, y, z ?? 0];

export const compassRoseDirection = (angle: number) => {
  if (angle >= 337.5 || angle <= 22.5) {
    return "N";
  } else if (angle < 67.5) {
    return "NE";
  } else if (angle <= 112.5) {
    return "E";
  } else if (angle < 157.5) {
    return "SE";
  } else if (angle <= 202.5) {
    return "S";
  } else if (angle < 247.5) {
    return "SW";
  } else if (angle <= 292.5) {
    return "W";
  } else {
    return "NW";
  }
};

export const distance2d = (from: Position, to: Position) =>
  distance(from, to, { units: "meters" });

export const distance3d = (from: Position, to: Position) => {
  const flatDistance = distance2d(from, to);
  const elevDistance = Math.abs(from[2] - to[2]);

  return Math.sqrt(flatDistance ** 2 + elevDistance ** 2);
};

export const drawBezier = (
  origin: { bearing: number; position: Position },
  destination: { bearing: number; position: Position }
) => {
  const straightLineDistance = distance2d(
    origin.position,
    destination.position
  );
  const originControlPoint = offsetPolar(
    origin.position,
    straightLineDistance * 0.5,
    origin.bearing
  );
  const destinationControlPoint = offsetPolar(
    destination.position,
    straightLineDistance * 0.5,
    destination.bearing
  );

  return new Bezier(
    posToBezierPoint(origin.position),
    posToBezierPoint(originControlPoint),
    posToBezierPoint(destinationControlPoint),
    posToBezierPoint(destination.position)
  )
    .getLUT(12)
    .map(bezierPointToPos);
};

export const loadMapIcons = (
  map: MapType,
  icons: { edgeLengthPx: number; name: string; img: string }[]
) => {
  for (const icon of icons) {
    const iconImg = new Image(icon.edgeLengthPx, icon.edgeLengthPx);

    iconImg.src = icon.img;
    iconImg.onload = () => {
      if (!map.hasImage(icon.name)) {
        map.addImage(icon.name, iconImg);
      }
    };
  }
};

export const offsetCartesian = (
  origin: Position,
  xOffset: number,
  yOffset: number,
  zOffset: number
): Position => {
  const yOffsetRads = yOffset / EARTH_RADIUS_M;
  const xOffsetRads =
    xOffset / (EARTH_RADIUS_M * Math.cos((Math.PI * origin[1]) / 180));

  return [
    origin[0] + radsToDegs(xOffsetRads),
    origin[1] + radsToDegs(yOffsetRads),
    origin[2] + zOffset,
  ];
};

export const offsetPolar = (
  origin: Position,
  distance: number,
  bearing: number
) => {
  return transformTranslate(
    {
      type: "Feature",
      geometry: { type: "Point", coordinates: origin },
      properties: {},
    },
    distance,
    bearing,
    { units: "meters" }
  ).geometry.coordinates;
};

export const posToBezierPoint = (position: Position) => ({
  x: position[0],
  y: position[1],
  z: position[2],
});

export const radsToDegs = (rads: number) => (rads * 180) / Math.PI;
