const EMAIL_ADDRESS_REGEX = /^[^@]+@[^@]+\.[^@]+$/;
// 4 decimal octets, dot-delineated
// NOTE: Does not verify that numbers fall within the 0-255 range
const IPV4_ADDRESS_REGEX = /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/;

export const validateEmailAddress = (candidate: string) =>
  EMAIL_ADDRESS_REGEX.test(candidate);

export const validateIpV4Address = (candidate: string) =>
  IPV4_ADDRESS_REGEX.test(candidate) &&
  candidate.split(".").every((octet) => parseInt(octet, 10) < 256);

export const validateIpAddress = (candidate: string) =>
  validateIpV4Address(candidate);

export const validatePasswordComplexity = (candidate: string) =>
  /[a-z]/.test(candidate) &&
  /[A-Z]/.test(candidate) &&
  /\d/.test(candidate) &&
  candidate.length >= 8;
