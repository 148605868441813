import React from "react";

import Button from "./Button";

import MagnifyingGlassIcon from "../../assets/icons/magnifyingGlass.svg";

const Input = (
  props: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > & {
    changed?: boolean;
    label?: string;
    recommendations?: string[];
    validate?: (
      value: React.InputHTMLAttributes<HTMLInputElement>["value"]
    ) => string;
    warning?: string;
  }
) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const ref = React.useRef();
  const {
    changed,
    className: providedClassName,
    disabled,
    label,
    maxLength,
    recommendations,
    required,
    title: providedTitle,
    type,
    validate,
    warning,
    ...rest
  } = props;
  const classNames = [providedClassName ?? ""];
  let title = providedTitle;
  let errorText = "";

  if (disabled) {
    if (typeof providedTitle === "string") {
      title += " (disabled)";
    } else {
      title = "(disabled)";
    }
  }

  if (required && disabled !== true && !props.value?.toString().length) {
    errorText = `*${label} is required`;
  } else if (validate !== undefined) {
    errorText = validate(props.value);
  }

  if (errorText) {
    classNames.push("error");
  } else if (warning && disabled !== true) {
    classNames.push("warning");
  } else if (changed && disabled !== true) {
    classNames.push("changed");
  }
  return (
    <>
      {label ? (
        <label
          htmlFor={props.id}
          style={{
            display: "inline-block",
            marginBottom: 4,
            cursor: "pointer",
          }}
        >
          {label}
          {required ? <span className="urgent"> *</span> : null}
        </label>
      ) : null}
      {recommendations ? (
        <datalist id={`autocomplete-${props.id}`}>
          {recommendations.map((recommendation) => (
            <option key={recommendation} value={recommendation} />
          ))}
        </datalist>
      ) : null}
      <div className="input-wrapper">
        {props.type === "search" ? (
          <img
            onClick={() => (ref as any).current.focus()}
            src={MagnifyingGlassIcon}
            alt="Search"
            width={18}
            height={18}
          />
        ) : null}
        <input
          className={classNames.join(" ")}
          disabled={disabled}
          list={recommendations ? `autocomplete-${props.id}` : undefined}
          maxLength={maxLength}
          ref={ref as any}
          required={required}
          tabIndex={0}
          title={title}
          type={
            (type === "number" &&
              (!props.value ||
                (props.value === "-" &&
                  (typeof props.min !== "number" || props.min < 0)))) ||
            (type === "password" && showPassword)
              ? "text"
              : type
          }
          {...rest}
        />
        {props.value ? (
          <>
            {props.type === "password" && setShowPassword ? (
              <Button
                onClick={() => setShowPassword(!showPassword)}
                style={{ width: 52 }}
                title={`${showPassword ? "Hide" : "Show"} password`}
              >
                {showPassword ? "Hide" : "Show"}
              </Button>
            ) : null}
            {disabled ? null : (
              <Button
                onClick={() => {
                  if (props.onChange) {
                    const input = (ref as any).current;

                    input.value = "";
                    input.focus();
                    props.onChange({
                      target: input,
                    } as any);
                  }
                }}
                title="Clear input"
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.4 14L0 12.6L5.6 7L0 1.4L1.4 0L7 5.6L12.6 0L14 1.4L8.4 7L14 12.6L12.6 14L7 8.4L1.4 14Z"
                    fill="currentColor"
                  />
                </svg>
              </Button>
            )}
          </>
        ) : null}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 4,
        }}
      >
        <span>
          {maxLength === undefined
            ? null
            : `${
                (props.value ?? "").toString().length
              }/${maxLength} characters`}
        </span>
        {disabled ? null : errorText ? (
          <span className="urgent">{errorText}</span>
        ) : (
          <span className="warning">{warning}</span>
        )}
      </div>
    </>
  );
};
export default Input;
