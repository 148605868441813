import React from "react";

const Toggle = (
  props: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > & { changed?: boolean; id: string }
) => {
  const { changed, ...rest } = props;

  return (
    <label
      className={`toggle-wrapper${changed ? " changed" : ""}`}
      htmlFor={props.id}
    >
      <input tabIndex={0} type="checkbox" {...rest} />
      <div className="toggle">
        <div className="slider" />
      </div>
    </label>
  );
};
export default Toggle;
