import { Map as MapType } from "mapbox-gl";
import React from "react";

const MapContext = React.createContext<{
  mapRef: any;
} | null>(null);

export const MapProvider = ({ children }: { children: React.ReactNode }) => {
  const mapRef = React.useRef<MapType>();

  return (
    <MapContext.Provider value={{ mapRef }}>{children}</MapContext.Provider>
  );
};

export const useMap = () => {
  const mapContext = React.useContext(MapContext);

  if (!mapContext) {
    throw new Error("useMap must be used inside the MapProvider");
  }
  return mapContext;
};
