import React from "react";

export const PanelCardField = ({
  className = "",
  label,
  value,
}: {
  className?: string;
  label?: string;
  value: string | number | null | undefined;
}) => (
  <div>
    {label ? <label>{label}</label> : null}
    <span className={className} title={(value ?? "-").toString()}>
      {value ?? "-"}
    </span>
  </div>
);
