import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  createTheme,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  ThemeProvider,
} from "@mui/material";

import Modal from "../Modal";
import { API_ABORT_MESSAGE, sendUserOperationRequest } from "../../lib/api";
import {
  canAccessService,
  CirrusRole,
  getRoleDescription,
  getRoleName,
  roles,
  User,
} from "../../lib/auth";
import { validateEmailAddress } from "../../lib/validation";
import { useAuth } from "../../state/authState";
import { useUI } from "../../state/uiState";

import config from "../../config";

// The global dark theme must be overridden for these modals.
const theme = createTheme({ palette: { mode: "light" } });

export const RemoveUserModal = ({
  user,
  removeUserFromList,
}: {
  user?: User;
  removeUserFromList: () => void;
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const { currentTenant, getDebug } = useAuth();
  const { addAlerts, currentOverlay, setOverlay } = useUI();
  const firstName = user?.firstName ?? "";
  const lastName = user?.lastName ?? "";
  const emailAddress = user?.emailAddress ?? "";

  const canEdit =
    window.location.host === "cirrus.panasonic.com" || getDebug() === "enabled";

  React.useEffect(() => {
    if (!canEdit || !currentOverlay.endsWith("-loading")) return;
    const abortController = new AbortController();

    const removeUserInner = async () => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: config.services.restApi.basePath,
        },
      });
      try {
        if (user?.id) {
          await sendUserOperationRequest(
            accessToken,
            currentTenant,
            {
              operation: "REMOVE",
              id: user.id,
            },
            abortController
          );
        }
        removeUserFromList();
        setOverlay(`${currentOverlay.split("-")[0]}-confirm`);
      } catch (error) {
        if (error === API_ABORT_MESSAGE) {
          console.warn(error);
        } else {
          console.error(error);
          addAlerts([
            {
              severity: "error",
              message: (error as any).toString(),
            },
          ]);
        }
        setOverlay("");
      }
    };
    removeUserInner();
    return () => {
      abortController.abort(API_ABORT_MESSAGE);
    };
  }, [
    addAlerts,
    canEdit,
    currentOverlay,
    currentTenant,
    emailAddress,
    firstName,
    getAccessTokenSilently,
    lastName,
    removeUserFromList,
    setOverlay,
    user,
  ]);

  if (currentOverlay.endsWith("-loading")) {
    return (
      <ThemeProvider theme={theme}>
        <Modal className="light" header={<h2>Loading...</h2>} />
      </ThemeProvider>
    );
  }
  const atRest = currentOverlay.split("-").length === 1;

  return (
    <ThemeProvider theme={theme}>
      <Modal
        className="light"
        header={
          <>
            <h2 style={{ fontSize: "24px", fontWeight: 400 }}>
              {atRest ? "Remove User" : "User removed"}
            </h2>
            <p style={{ fontSize: "16px" }}>
              {atRest
                ? "Are you sure you want to remove this user? Removing this user will revoke their access to your tenant and all of its data."
                : "The user has been successfully removed from your tenant."}
            </p>
          </>
        }
        footer={
          <>
            {atRest ? (
              <Button
                onClick={() => setOverlay("")}
                size="large"
                style={{
                  height: 42,
                  marginLeft: 12,
                  marginTop: 32,
                  border: "solid 1px transparent",
                }}
              >
                Cancel
              </Button>
            ) : null}
            <Button
              disabled={!canEdit}
              onClick={() => {
                if (atRest) {
                  setOverlay(`${currentOverlay}-loading`);
                } else {
                  setOverlay("");
                }
              }}
              size="large"
              style={{
                height: 42,
                marginLeft: 12,
                marginTop: 32,
                border: "solid 1px transparent",
              }}
              variant="contained"
            >
              {atRest ? "Confirm" : "Ok"}
            </Button>
          </>
        }
      />
    </ThemeProvider>
  );
};

const AddOrEditUserModal = ({
  mode,
  showConfirmationScreen = false,
  user,
  upsertUserInList,
  users,
}: {
  mode: "add" | "edit";
  showConfirmationScreen?: boolean;
  user: User;
  upsertUserInList: (newUser: User) => void;
  users: User[];
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const { currentTenant, currentUser, getDebug } = useAuth();
  const navigate = useNavigate();
  const { addAlerts, currentOverlay, setOverlay } = useUI();
  const [dirtyUser, setDirtyUser] = React.useState(user);
  const roleName = getRoleName(dirtyUser.authMetadata);

  const fieldsAreValid =
    dirtyUser.firstName &&
    dirtyUser.lastName &&
    validateEmailAddress(dirtyUser.emailAddress);
  const atLeastOneFieldHasBeenUpdated =
    user.firstName !== dirtyUser.firstName ||
    user.lastName !== dirtyUser.lastName ||
    user.emailAddress !== dirtyUser.emailAddress ||
    getRoleName(user.authMetadata) !== getRoleName(dirtyUser.authMetadata);

  const canConfirm =
    fieldsAreValid && (mode === "add" || atLeastOneFieldHasBeenUpdated);

  const setDirtyUserRole = (newRoleName: string) => {
    if (!["Administrator", "Guest", "Standard"].includes(newRoleName)) return;

    setDirtyUser((prevDirtyUser) => ({
      ...prevDirtyUser,
      authMetadata: {
        ...(roles.find((role) => role.name === newRoleName)
          ?.permissions as any),
      },
    }));
  };

  const { id, firstName, lastName, emailAddress, authMetadata } = dirtyUser;

  const canEdit =
    window.location.host === "cirrus.panasonic.com" || getDebug() === "enabled";

  React.useEffect(() => {
    if (!canEdit || !currentOverlay.endsWith("-loading")) return;

    const abortController = new AbortController();

    // TODO: Edit here
    const addOrEditUser = async () => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: config.services.restApi.basePath,
        },
      });

      try {
        let response: User;
        switch (mode) {
          case "add": {
            response = await sendUserOperationRequest(
              accessToken,
              currentTenant,
              {
                operation: "ADD",
                firstName: firstName,
                lastName: lastName,
                emailAddress: emailAddress,
                authMetadata: authMetadata,
                // TODO: TypeGuard
                role: roleName as CirrusRole,
              },
              abortController
            );
            break;
          }
          case "edit": {
            response = await sendUserOperationRequest(
              accessToken,
              currentTenant,
              {
                operation: "UPDATE",
                id: user.id,
                authMetadata: authMetadata,
                role: roleName as CirrusRole,
              },
              abortController
            );
            break;
          }
        }

        upsertUserInList(response);
        setDirtyUser(response);

        if (showConfirmationScreen) {
          setOverlay(`${currentOverlay.split("-")[0]}-confirm`);
        } else {
          setOverlay("");
        }
      } catch (error) {
        if (error === API_ABORT_MESSAGE) {
          console.warn(error);
        } else {
          console.error(error);
          addAlerts([
            {
              severity: "error",
              message: (error as any).toString(),
            },
          ]);
        }
        setOverlay("");
      }
    };

    addOrEditUser();

    return () => {
      abortController.abort(API_ABORT_MESSAGE);
    };
  }, [
    authMetadata,
    canEdit,
    currentOverlay,
    currentTenant,
    dirtyUser,
    emailAddress,
    firstName,
    getAccessTokenSilently,
    id,
    lastName,
    mode,
    setOverlay,
    showConfirmationScreen,
    upsertUserInList,
    getDebug,
    roleName,
    user,
    addAlerts,
  ]);

  if (currentOverlay.endsWith("-loading")) {
    return (
      <ThemeProvider theme={theme}>
        <Modal className="light" header={<h2>Loading...</h2>} />
      </ThemeProvider>
    );
  }

  if (currentOverlay.endsWith("-confirm")) {
    return (
      <ThemeProvider theme={theme}>
        <Modal
          className="light"
          header={
            <>
              <h2 style={{ fontSize: "24px", fontWeight: 400 }}>
                {mode === "add" ? "User added" : "User updated"}
              </h2>
              <p style={{ fontSize: "16px" }}>
                The user has been successfully{" "}
                {mode === "add" ? "added to your tenant" : "updated"}. To see
                their detailed information, please visit their user page.
              </p>
            </>
          }
          footer={
            <>
              <Button
                onClick={() => setOverlay("")}
                size="large"
                style={{
                  height: 42,
                  marginLeft: 12,
                  marginTop: 32,
                }}
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  navigate(`/users?u=${encodeURIComponent(id)}`);
                  setOverlay("");
                }}
                size="large"
                style={{
                  height: 42,
                  marginLeft: 12,
                  marginTop: 32,
                  border: "solid 1px transparent",
                }}
                variant="contained"
              >
                View user
              </Button>
            </>
          }
        />
      </ThemeProvider>
    );
  }
  return (
    <ThemeProvider theme={theme}>
      <Modal
        className="light"
        footer={
          <>
            <Button
              onClick={() => setOverlay("")}
              size="large"
              style={{
                height: 42,
                marginLeft: 12,
                marginTop: 32,
              }}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              disabled={!canEdit || !canConfirm}
              onClick={() => setOverlay(`${currentOverlay}-loading`)}
              size="large"
              style={{
                height: 42,
                marginLeft: 12,
                marginTop: 32,
                border: "solid 1px transparent",
              }}
              variant="contained"
            >
              Confirm
            </Button>
          </>
        }
        header={
          <>
            <h2 style={{ fontSize: "24px", fontWeight: 400 }}>
              {mode === "add" ? "Add User" : "Edit User"}
            </h2>
            <p style={{ paddingBottom: 24, fontSize: "16px" }}>
              {mode === "add"
                ? "Fill out the form below to add a new user to your tenant."
                : "Make changes to the form below to update this user."}
            </p>
          </>
        }
      >
        <div style={{ width: 840 }}>
          <h3
            style={{
              height: 32,
              marginBottom: 8,
              fontSize: "20px",
              lineHeight: "32px",
            }}
          >
            Personal Information
          </h3>
          <TextField
            disabled={!canEdit || mode === "edit"}
            label="First Name"
            style={{ width: "100%", marginBottom: 24 }}
            value={dirtyUser.firstName}
            onChange={(e) =>
              setDirtyUser((prevDirtyUser) => ({
                ...prevDirtyUser,
                firstName: e.target.value,
              }))
            }
          />
          <TextField
            disabled={!canEdit || mode === "edit"}
            label="Last Name"
            style={{ width: "100%", marginBottom: 24 }}
            value={dirtyUser.lastName}
            onChange={(e) =>
              setDirtyUser((prevDirtyUser) => ({
                ...prevDirtyUser,
                lastName: e.target.value,
              }))
            }
          />
          <TextField
            disabled={!canEdit || mode === "edit"}
            label="Email Address"
            style={{ width: "100%", marginBottom: 24 }}
            value={dirtyUser.emailAddress}
            /**
             * TODO: Make this condition clean.
             * Currently allow user create submission even if there is existing user.
             */
            error={
              mode === "add" &&
              users
                .map((e) => e.emailAddress.toLowerCase())
                .includes(dirtyUser.emailAddress.toLowerCase())
            }
            helperText={
              mode === "add" &&
              users
                .map((e) => e.emailAddress.toLowerCase())
                .includes(dirtyUser.emailAddress.toLowerCase())
                ? "an user with same email address already exists"
                : ""
            }
            onChange={(e) =>
              setDirtyUser((prevDirtyUser) => ({
                ...prevDirtyUser,
                emailAddress: e.target.value,
              }))
            }
          />
          {canAccessService(
            currentUser.authMetadata,
            "web_client:user_management"
          ) ? (
            <>
              <h3
                style={{
                  height: 32,
                  marginBottom: 8,
                  fontSize: "20px",
                  lineHeight: "32px",
                }}
              >
                System Access
              </h3>
              <FormControl
                style={{
                  border: "1px solid #e0e0e0",
                  borderRadius: 8,
                  width: "100%",
                  marginBottom: 24,
                  padding: "12px 16px",
                }}
              >
                <FormLabel
                  style={{
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: 1.5,
                  }}
                >
                  User Role
                </FormLabel>
                <p
                  style={{
                    fontSize: "14px",
                    lineHeight: 1.4,
                    opacity: 0.87,
                  }}
                >
                  A user's role determines their level of access to Cirrus.{" "}
                  {roleName === "Administrator" ? (
                    <>
                      Administrators can only be demoted or removed by{" "}
                      <a
                        href="mailto:v2xsupport@us.panasonic.com"
                        style={{ padding: 0, color: "#6f6f6f" }}
                      >
                        Cirrus support
                      </a>
                      .
                    </>
                  ) : null}
                </p>
                <RadioGroup
                  row
                  style={{ height: 46 }}
                  value={roleName}
                  onChange={(e) => setDirtyUserRole(e.target.value)}
                >
                  <FormControlLabel
                    control={<Radio />}
                    disabled={
                      !canEdit ||
                      user.authMetadata.user_permission.includes("users:admin")
                    }
                    label="Guest"
                    value="Guest"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    disabled={
                      !canEdit ||
                      user.authMetadata.user_permission.includes("users:admin")
                    }
                    label="Standard"
                    value="Standard"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    disabled={
                      !canEdit ||
                      user.authMetadata.user_permission.includes("users:admin")
                    }
                    label="Administrator"
                    value="Administrator"
                  />
                  {roleName === "Custom" ? (
                    <FormControlLabel
                      control={<Radio />}
                      disabled={
                        !canEdit ||
                        user.authMetadata.user_permission.includes(
                          "users:admin"
                        )
                      }
                      label="Custom"
                      value="Custom"
                    />
                  ) : null}
                </RadioGroup>
              </FormControl>
              <h3
                style={{
                  height: 32,
                  marginBottom: 8,
                  fontSize: "20px",
                  lineHeight: "32px",
                }}
              >
                Application Access
              </h3>
              <div
                className="service-access-summary"
                style={{
                  border: "1px solid #e0e0e0",
                  borderRadius: 8,
                  width: "100%",
                  marginBottom: 24,
                  padding: "12px 16px",
                  backgroundColor: "#f7f9fe",
                  fontSize: "14px",
                  lineHeight: 1.4,
                }}
              >
                {getRoleDescription(
                  dirtyUser.authMetadata,
                  getDebug() === "enabled",
                  "create"
                )}
              </div>
            </>
          ) : null}
        </div>
      </Modal>
    </ThemeProvider>
  );
};
export default AddOrEditUserModal;
