import { createTheme } from "@mui/material";

import gdotLogo from "./assets/tenants/gdotLogo.svg";
import psnLogo from "./assets/tenants/psnLogo.svg";
import udotLogo from "./assets/tenants/udotLogo.svg";

import { ServiceNames } from "./lib/auth";

const getEnvironmentConfig = () => {
  switch (window.location.host) {
    case "cirrus.panasonic.com":
      return {
        name: "prod",
        auth0ClientId: "SREbWPAiCFHE4L07nalnDsMeCGW10oHi",
        servicesSubdomain: "",
      };
    case "qa.cirrus.panasonic.com":
      return {
        name: "qa",
        auth0ClientId: "BH9Zds1c0MA5BbDk7ecIyFGmaYuQdCDI",
        servicesSubdomain: "qa.",
      };
    case "dev.cirrus.panasonic.com":
    case "localhost:3000":
      return {
        name: "dev",
        auth0ClientId: "RFddZBxDi2kEPmAJkHX0egl75uqJtgy1",
        servicesSubdomain: "dev.",
      };
    default:
      throw new Error("Invalid host");
  }
};

export const serviceBasePaths: Omit<
  { [K in ServiceNames]: string },
  "v2n_monitoring"
> = {
  dashboards: "https://dashboards.cirrus.panasonic.com/superset/",
  data_dictionary:
    "https://app.secoda.co/api/v1/auth/saml/us+panasonic+com/sp-initiate/",
  data_repository: "https://smo-panasonic.snowflakecomputing.com/",
  "web_client:device_management": "/device-manager",
  "web_client:time_travel": "/time-travel",
  "web_client:user_management": "/users",
};

const config = {
  assets: {
    headerLogo:
      "https://cirrus-logos.s3.us-west-2.amazonaws.com/Cirrus_logo_light--inline-UI.svg",
  },
  // Continental US bounding box
  defaultBbox: [
    [-124.7844079, 24.7433195],
    [-66.9513812, 49.3457868],
  ],
  environmentName: getEnvironmentConfig().name,
  services: {
    auth0: {
      clientId: getEnvironmentConfig().auth0ClientId,
      domain: "auth.cirrus.panasonic.com",
    },
    restApi: {
      basePath: `https://rest-api.${
        getEnvironmentConfig().servicesSubdomain
      }cirrus.panasonic.com`,
    },
  },
  cards: [
    {
      title: "Fixed Devices",
      description:
        "Monitor your fixed infrastructure ecosystem and track performance over time.",
      link: `${serviceBasePaths.dashboards}dashboard/fixed_devices/`,
      service: "dashboards",
    },
    {
      title: "Traffic Insights",
      description: "View traffic events.",
      link: `${serviceBasePaths.dashboards}dashboard/traffic_insights/`,
      service: "dashboards",
    },
    {
      title: "V2X Data Elements",
      description: "Explore V2X data trends and view message details.",
      link: `${serviceBasePaths.dashboards}dashboard/v2x_data_elements/`,
      service: "dashboards",
    },
    {
      title: "Connected Intersections",
      description:
        "Monitor Signal Priority and Preemption (EVP/TSP) system performance.",
      service: "dashboards",
    },
    {
      title: "Time Travel",
      description: "View sites and replay interactions on a map.",
      link: serviceBasePaths["web_client:time_travel"],
      service: "web_client:time_travel",
    },
    {
      title: "Device Manager",
      description: "View and manage hardware devices.",
      link: serviceBasePaths["web_client:device_management"],
      badge: "BETA",
      service: "web_client:device_management",
    },
    {
      title: "Data Repository",
      description: "Explore raw data and run your own queries.",
      link: serviceBasePaths["data_repository"],
      service: "data_repository",
    },
  ] as {
    title: string;
    description: string;
    link?: string;
    badge?: string;
    service: ServiceNames;
  }[],
  theme: createTheme({
    palette: {
      mode: "dark",
      action: {
        selected: "#393939",
      },
      background: {
        default: "#252525",
      },
      info: {
        main: "#29b6f6",
      },
      primary: {
        main: "#1876d1",
      },
    },
  }),
  // TODO: Replace this with a backend tenant metadata lookup
  tenants: {
    GDOT: {
      logo: gdotLogo,
      name: "Georgia Department of Transportation",
    },
    PSN: {
      logo: psnLogo,
      name: "Panasonic Smart Mobility Office",
    },
    UDOT: {
      logo: udotLogo,
      name: "Utah Department of Transportation",
    },
  },
};
export default config;
