import dayjs from "dayjs";
import { LngLatBounds } from "mapbox-gl";
import React from "react";
import Map, { Layer, NavigationControl, Source } from "react-map-gl";
import { useSearchParams } from "react-router-dom";
import { Scrubber } from "react-scrubber";
import { useDebounceCallback, useLocalStorage } from "usehooks-ts";

import { epochToTime, fromStringToDayjs } from "../../lib/formatting";
import { loadMapIcons } from "../../lib/geo";
import { useSearchParam } from "../../lib/hooks";
import { useAuth } from "../../state/authState";
import { useMap } from "../../state/mapState";
import { useUI } from "../../state/uiState";

import CheckboxCheckedIcon from "../../assets/icons/checkboxChecked.svg";
import CheckboxUncheckedIcon from "../../assets/icons/checkboxUnchecked.svg";
import ChevronIcon from "../../assets/icons/chevronVehicle.svg";
import LayerStackIcon from "../../assets/icons/layerStack.svg";
import PauseIcon from "../../assets/icons/pause.svg";
import SiteIcon from "../../assets/icons/site.svg";

import { NormalizedSite } from "../../lib/devices/deviceTypes";
import {
  FeatureCollection,
  LineStringGeometry,
  PointGeometry,
  PolygonGeometry,
  Position,
} from "../../lib/json";

const MapWrapper = ({
  focusCoordinates,
  laneCenterlines,
  laneSurfaces,
  loading,
  mapReferencePoints,
  messageIds,
  movementCenterlines,
  movementSurfaces,
  sites,
  vehicleTelemetry,
  addMessageId,
  setMessageIds,
}: {
  focusCoordinates: string;
  laneCenterlines: FeatureCollection<LineStringGeometry, {}>;
  laneSurfaces: FeatureCollection<PolygonGeometry, {}>;
  loading: boolean;
  mapReferencePoints: FeatureCollection<PointGeometry, {}>;
  messageIds: string[];
  movementCenterlines: FeatureCollection<LineStringGeometry, {}>;
  movementSurfaces: FeatureCollection<PolygonGeometry, {}>;
  sites: NormalizedSite[];
  vehicleTelemetry: FeatureCollection<PointGeometry, {}>;
  addMessageId: (messageId: string) => void;
  setMessageIds: (messageIds: string[]) => void;
}) => {
  const { getDebug } = useAuth();
  const { mapRef } = useMap();
  const [from] = useSearchParam("from");
  const [, setSource] = useSearchParam("source");
  const [cursor, setCursor] = React.useState("auto");
  const [scrubRaw, setScrubRaw] = useSearchParam("scrub");
  const [paused, setPaused] = React.useState(true);
  const {
    currentOverlay,
    assetsPanelOpen,
    messagesPanelOpen,
    setOverlay,
    setHoveredObject,
  } = useUI();

  const scrub = parseFloat(scrubRaw ? scrubRaw : "0");
  const debug = getDebug();

  const setScrub = (num: number) => {
    setPaused(true);
    setScrubRaw(num <= 0 ? "" : num >= 300 ? "300" : num.toFixed(1));
  };
  const setScrubDebounced = useDebounceCallback(
    (num) => {
      setScrub(num);
    },
    100,
    { maxWait: 500 }
  );

  const [, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    if (paused) return;

    let expected = Date.now() + 100;
    const step = () => {
      const dt = Date.now() - expected;

      setSearchParams((prevSearchParams) => {
        const currentScrub = parseFloat(prevSearchParams.get("scrub") ?? "0");

        if (currentScrub + 0.1 >= 300) {
          setPaused(true);
        }
        prevSearchParams.set("scrub", (currentScrub + 0.1).toFixed(1));

        return prevSearchParams;
      });
      expected += 100;
      timeout = setTimeout(step, Math.max(0, 100 - dt));
    };
    let timeout = setTimeout(step, 100);

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paused]);

  React.useEffect(() => {
    if (!mapRef.current) return;

    const coordinates = focusCoordinates
      .split("|")
      .map(
        (coords) =>
          coords.split(",").map((part) => parseFloat(part)) as Position
      );
    const bounds = new LngLatBounds(
      coordinates[0] as any,
      coordinates[0] as any
    );

    for (let i = 1; i < coordinates.length; i += 1) {
      bounds.extend(coordinates[i] as any);
    }
    mapRef.current.fitBounds(bounds, {
      maxZoom: coordinates.length < 2 ? 18 : 15,
      padding: 40,
    });
  }, [focusCoordinates, mapRef]);

  const hasMessages = messageIds.length > 0;

  const coordinates = focusCoordinates
    .split("|")
    .map(
      (coords) => coords.split(",").map((part) => parseFloat(part)) as Position
    );
  const bounds = new LngLatBounds(coordinates[0] as any, coordinates[0] as any);

  for (let i = 1; i < coordinates.length; i += 1) {
    bounds.extend(coordinates[i] as any);
  }

  React.useEffect(() => {
    if (!mapRef.current) return;

    mapRef.current.resize();
  }, [mapRef, assetsPanelOpen, hasMessages, messagesPanelOpen]);

  const fromVal = from
    ? fromStringToDayjs(from)
    : dayjs().subtract(300, "seconds");
  const to = fromVal.clone().add(300, "seconds");

  const [mapStyle, setMapStyle] = useLocalStorage(
    "mapStyle",
    "mapbox://styles/mapbox/dark-v11"
  );

  const [showSitePositions, setShowSitePositions] = useLocalStorage(
    "showSitePositions",
    true
  );
  const [showLaneSurfaces, setShowLaneSurfaces] = useLocalStorage(
    "showLaneSurfaces",
    true
  );
  const [showLaneCenterlines, setShowLaneCenterlines] = useLocalStorage(
    "showLaneCenterlines",
    true
  );
  const [showMapReferencePoints, setShowMapReferencePoints] = useLocalStorage(
    "showMapReferencePoints",
    true
  );
  const [showMovements, setShowMovements] = useLocalStorage(
    "showMovements",
    true
  );
  const [showBasicSafetyMessages, setShowBasicSafetyMessages] = useLocalStorage(
    "showBasicSafetyMessages",
    true
  );
  const [showSignalRequestMessages, setShowSignalRequestMessages] =
    useLocalStorage("showSignalRequestMessages", true);

  const now = parseFloat((fromVal.valueOf() * 0.001 + scrub).toFixed(1));

  return (
    <Map
      cursor={loading ? "wait" : cursor}
      initialViewState={{
        bounds,
        fitBoundsOptions: {
          maxZoom: coordinates.length < 2 ? 18 : 15,
          padding: 40,
        },
      }}
      mapboxAccessToken="pk.eyJ1IjoibWltYW5pbSIsImEiOiJja2hqbmN5ZHQwMjd4MnJtOWwyMmtybmQ1In0.fHHklenDLH2QarFKCdpByA" // TODO: Make env var
      mapStyle={mapStyle === "no-basemap" ? undefined : mapStyle}
      maxPitch={0}
      interactiveLayerIds={[
        "movementSurfaces",
        "movementCenterlines",
        "laneSurfaces",
        "laneCenterlines",
        "basicSafetyMessagesBg",
        "basicSafetyMessagesIcon",
        "signalRequestMessagesRequest",
        "signalRequestMessagesUpdate",
        "signalRequestMessagesCancel",
        "signalRequestMessagesIcon",
        "standardSites",
        "intersectionSites",
      ]}
      onClick={(e) => {
        if (!e.features?.length) return;

        switch ((e.features[0] as any).layer.id) {
          case "movementSurfaces":
          case "movementCenterlines":
          case "laneSurfaces":
          case "laneCenterlines":
          case "basicSafetyMessagesBg":
          case "basicSafetyMessagesIcon":
          case "signalRequestMessagesRequest":
          case "signalRequestMessagesUpdate":
          case "signalRequestMessagesCancel":
          case "signalRequestMessagesIcon":
            addMessageId((e.features[0].properties as any).messageId);
            break;
          case "standardSites":
          case "intersectionSites":
            setMessageIds([]);
            setSource((e.features[0].properties as any).sourceId);
            break;
        }
      }}
      onStyleData={(e) =>
        loadMapIcons((e as any).target, [
          { edgeLengthPx: 7, name: "chevronSmall", img: ChevronIcon },
          { edgeLengthPx: 10.5, name: "chevronLarge", img: ChevronIcon },
          { edgeLengthPx: 32, name: "siteSmall", img: SiteIcon },
          { edgeLengthPx: 48, name: "siteLarge", img: SiteIcon },
        ])
      }
      onMouseEnter={() => setCursor("pointer")}
      onMouseLeave={() => setCursor("auto")}
      onMouseMove={(e) => {
        if (!e.features?.length) {
          setHoveredObject("");
          return;
        }

        switch ((e.features[0] as any).layer.id) {
          case "movementSurfaces":
          case "movementCenterlines":
          case "laneSurfaces":
          case "laneCenterlines":
          case "basicSafetyMessagesBg":
          case "basicSafetyMessagesIcon":
          case "signalRequestMessagesRequest":
          case "signalRequestMessagesUpdate":
          case "signalRequestMessagesCancel":
          case "signalRequestMessagesIcon":
            setHoveredObject((e.features[0].properties as any).messageId);
            break;
          case "standardSites":
          case "intersectionSites":
            setHoveredObject((e.features[0].properties as any).sourceId);
            break;
        }
      }}
      ref={mapRef}
    >
      <button
        className="map-control"
        onClick={() =>
          setOverlay(currentOverlay === "layerPicker" ? "" : "layerPicker")
        }
        style={{
          position: "absolute",
          zIndex: 1,
          width: 29,
          height: 29,
          top: 121,
          right: 16,
          padding: 2.5,
          borderRadius: 4,
          boxShadow: "0 0 0 2px #0000001a",
          cursor: "pointer",
        }}
        tabIndex={0}
        title={`${
          currentOverlay === "layerPicker" ? "Close" : "Open"
        } the layer picker`}
      >
        <img
          src={LayerStackIcon}
          alt={`${
            currentOverlay === "layerPicker" ? "Close" : "Open"
          } the layer picker`}
          width={24}
          height={24}
        />
      </button>
      {currentOverlay === "layerPicker" ? (
        <div
          id="layer-picker"
          style={{
            position: "absolute",
            width: 240,
            maxHeight: "calc(100vh - 358px)",
            padding: "24px 24px 30px 24px",
            top: 150,
            right: 16,
            borderRadius: 4,
            backgroundColor: "#262626",
            boxShadow: "0 0 0 2px #0000001a",
            opacity: 0.85,
            overflowY: "auto",
          }}
        >
          <h2>Map Style</h2>
          <div
            onClick={() => setMapStyle("mapbox://styles/mapbox/light-v11")}
            style={{
              display: "flex",
              alignItems: "center",
              height: 36,
              cursor: "pointer",
            }}
            title="Mapbox Light v11"
          >
            <div
              id="light"
              style={{
                width: 18,
                height: 18,
                border: `5px solid ${
                  mapStyle === "mapbox://styles/mapbox/light-v11"
                    ? "#488cf1"
                    : "#ffffff"
                }`,
                borderRadius: "50%",
                backgroundColor: "#ffffff",
              }}
            />
            <label
              htmlFor="light"
              style={{ userSelect: "none", cursor: "pointer" }}
            >
              Light
            </label>
          </div>
          <div
            onClick={() => setMapStyle("mapbox://styles/mapbox/dark-v11")}
            style={{
              display: "flex",
              alignItems: "center",
              height: 36,
              cursor: "pointer",
            }}
            title="Mapbox Dark v11"
          >
            <div
              id="dark"
              style={{
                width: 18,
                height: 18,
                border: `5px solid ${
                  mapStyle === "mapbox://styles/mapbox/dark-v11"
                    ? "#488cf1"
                    : "#ffffff"
                }`,
                borderRadius: "50%",
                backgroundColor: "#ffffff",
              }}
            />
            <label
              htmlFor="dark"
              style={{ userSelect: "none", cursor: "pointer" }}
            >
              Dark
            </label>
          </div>
          <div
            onClick={() => setMapStyle("mapbox://styles/mapbox/satellite-v9")}
            style={{
              display: "flex",
              alignItems: "center",
              height: 36,
              cursor: "pointer",
            }}
            title="Mapbox Satellite v9"
          >
            <div
              id="satellite"
              style={{
                width: 18,
                height: 18,
                border: `5px solid ${
                  mapStyle === "mapbox://styles/mapbox/satellite-v9"
                    ? "#488cf1"
                    : "#ffffff"
                }`,
                borderRadius: "50%",
                backgroundColor: "#ffffff",
              }}
            />
            <label
              htmlFor="satellite"
              style={{ userSelect: "none", cursor: "pointer" }}
            >
              Satellite
            </label>
          </div>
          {debug === "enabled" ? (
            <div
              onClick={() => setMapStyle("no-basemap")}
              style={{
                display: "flex",
                alignItems: "center",
                height: 36,
                cursor: "pointer",
              }}
              title="No Basemap"
            >
              <div
                id="no-basemap"
                style={{
                  width: 18,
                  height: 18,
                  border: `5px solid ${
                    mapStyle === "no-basemap" ? "#488cf1" : "#ffffff"
                  }`,
                  borderRadius: "50%",
                  backgroundColor: "#ffffff",
                }}
              />
              <label
                htmlFor="no-basemap"
                style={{ userSelect: "none", cursor: "pointer" }}
              >
                No Basemap
              </label>
            </div>
          ) : null}
          <h2>Data Visualization</h2>
          <div
            onClick={() => setShowMovements(!showMovements)}
            style={{
              display: "flex",
              alignItems: "center",
              height: 36,
              cursor: "pointer",
            }}
          >
            <div id="showMovements" style={{ height: 18 }}>
              <img
                src={
                  showMovements ? CheckboxCheckedIcon : CheckboxUncheckedIcon
                }
                alt={`${showMovements ? "Hide" : "Show"} SPaT Movements`}
                width={18}
                height={18}
              />
            </div>
            <label
              htmlFor="showMovements"
              style={{ userSelect: "none", cursor: "pointer" }}
            >
              SPaT Movements
            </label>
          </div>
          <div
            onClick={() => setShowLaneSurfaces(!showLaneSurfaces)}
            style={{
              display: "flex",
              alignItems: "center",
              height: 36,
              cursor: "pointer",
            }}
          >
            <div id="showLaneSurfaces" style={{ height: 18 }}>
              <img
                src={
                  showLaneSurfaces ? CheckboxCheckedIcon : CheckboxUncheckedIcon
                }
                alt={`${showLaneSurfaces ? "Hide" : "Show"} Map Lane Surfaces`}
                width={18}
                height={18}
              />
            </div>
            <label
              htmlFor="showLaneSurfaces"
              style={{ userSelect: "none", cursor: "pointer" }}
            >
              Map Lane Surfaces
            </label>
          </div>
          <div
            onClick={() => setShowLaneCenterlines(!showLaneCenterlines)}
            style={{
              display: "flex",
              alignItems: "center",
              height: 36,
              cursor: "pointer",
            }}
          >
            <div id="showLaneCenterlines" style={{ height: 18 }}>
              <img
                src={
                  showLaneCenterlines
                    ? CheckboxCheckedIcon
                    : CheckboxUncheckedIcon
                }
                alt={`${
                  showLaneCenterlines ? "Hide" : "Show"
                } Map Lane Centerlines`}
                width={18}
                height={18}
              />
            </div>
            <label
              htmlFor="showLaneCenterlines"
              style={{ userSelect: "none", cursor: "pointer" }}
            >
              Map Lane Centerlines
            </label>
          </div>
          {debug === "enabled" ? (
            <div
              onClick={() => setShowMapReferencePoints(!showMapReferencePoints)}
              style={{
                display: "flex",
                alignItems: "center",
                height: 36,
                cursor: "pointer",
              }}
            >
              <div id="showMapReferencePoints" style={{ height: 18 }}>
                <img
                  src={
                    showMapReferencePoints
                      ? CheckboxCheckedIcon
                      : CheckboxUncheckedIcon
                  }
                  alt={`${
                    showMapReferencePoints ? "Hide" : "Show"
                  } Map Reference Points`}
                  width={18}
                  height={18}
                />
              </div>
              <label
                htmlFor="showMapReferencePoints"
                style={{ userSelect: "none", cursor: "pointer" }}
              >
                Map Reference Points
              </label>
            </div>
          ) : null}
          <div
            onClick={() => setShowBasicSafetyMessages(!showBasicSafetyMessages)}
            style={{
              display: "flex",
              alignItems: "center",
              height: 36,
              cursor: "pointer",
            }}
          >
            <div id="showBasicSafetyMessages" style={{ height: 18 }}>
              <img
                src={
                  showBasicSafetyMessages
                    ? CheckboxCheckedIcon
                    : CheckboxUncheckedIcon
                }
                alt={`${showBasicSafetyMessages ? "Hide" : "Show"} BSMs`}
                width={18}
                height={18}
              />
            </div>
            <label
              htmlFor="showBasicSafetyMessages"
              style={{ userSelect: "none", cursor: "pointer" }}
            >
              BSMs
            </label>
          </div>
          <div
            onClick={() =>
              setShowSignalRequestMessages(!showSignalRequestMessages)
            }
            style={{
              display: "flex",
              alignItems: "center",
              height: 36,
              cursor: "pointer",
            }}
          >
            <div id="showSignalRequestMessages" style={{ height: 18 }}>
              <img
                src={
                  showSignalRequestMessages
                    ? CheckboxCheckedIcon
                    : CheckboxUncheckedIcon
                }
                alt={`${showSignalRequestMessages ? "Hide" : "Show"} SRMs`}
                width={18}
                height={18}
              />
            </div>
            <label
              htmlFor="showSignalRequestMessages"
              style={{ userSelect: "none", cursor: "pointer" }}
            >
              SRMs
            </label>
          </div>
          {debug === "enabled" ? (
            <div
              onClick={() => setShowSitePositions(!showSitePositions)}
              style={{
                display: "flex",
                alignItems: "center",
                height: 36,
                cursor: "pointer",
              }}
            >
              <div id="showSitePositions" style={{ height: 18 }}>
                <img
                  src={
                    showSitePositions
                      ? CheckboxCheckedIcon
                      : CheckboxUncheckedIcon
                  }
                  alt={`${showSitePositions ? "Hide" : "Show"} Sites`}
                  width={18}
                  height={18}
                />
              </div>
              <label
                htmlFor="showSitePositions"
                style={{ userSelect: "none", cursor: "pointer" }}
              >
                Site Positions
              </label>
            </div>
          ) : null}
        </div>
      ) : null}
      <NavigationControl />
      {from ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            position: "absolute",
            bottom: 20,
            left: "calc(50% - 332px)",
            width: 664,
            height: 112,
            backgroundColor: "#262626",
            opacity: 0.85,
            borderRadius: 8,
            padding: "24px 16px 16px 16px",
          }}
        >
          <div style={{ display: "flex" }}>
            <span
              style={{
                marginRight: 16,
                fontSize: "16px",
                whiteSpace: "nowrap",
              }}
            >
              {epochToTime(fromVal.valueOf(), 0, false)}
            </span>
            <Scrubber
              min={0}
              max={300}
              value={scrub}
              onScrubStart={(val) => setScrub(parseFloat(val.toFixed(1)))}
              onScrubChange={(val) =>
                setScrubDebounced(parseFloat(val.toFixed(1)))
              }
              onScrubEnd={(val) => setScrub(parseFloat(val.toFixed(1)))}
              tooltip={{
                className: "tooltip",
                enabledOnHover: true,
                enabledOnScrub: true,
                formatString: (val) =>
                  epochToTime(fromVal.add(val, "seconds").valueOf(), 1, false),
              }}
            />
            <span
              style={{ marginLeft: 16, fontSize: "16px", whiteSpace: "nowrap" }}
            >
              {epochToTime(to.valueOf(), 0, false)}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: 40,
              }}
            >
              <button
                disabled={scrub === 0}
                onClick={() => setScrub(scrub - 10)}
                style={{ height: 33 }}
                title="Click to scrub 10 seconds backward"
              >
                <svg
                  width="30"
                  height="33"
                  viewBox="0 0 30 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.9987 32.834C13.0329 32.834 11.191 32.4622 9.47303 31.7186C7.75509 30.975 6.25723 29.9644 4.97945 28.6866C3.7017 27.4089 2.69103 25.911 1.94745 24.193C1.20384 22.4751 0.832031 20.6332 0.832031 18.6674H3.33199C3.33199 21.9174 4.46393 24.6744 6.72782 26.9383C8.99171 29.2021 11.7487 30.3341 14.9987 30.3341C18.2487 30.3341 21.0056 29.2021 23.2695 26.9383C25.5334 24.6744 26.6653 21.9174 26.6653 18.6674C26.6653 15.4174 25.5334 12.6605 23.2695 10.3966C21.0056 8.1327 18.2487 7.00076 14.9987 7.00076H14.5563L17.2038 9.64821L15.4474 11.4558L9.74236 5.73476L15.4794 0.0136719L17.2358 1.82134L14.5563 4.5008H14.9987C16.9645 4.5008 18.8063 4.8726 20.5243 5.61621C22.2422 6.3598 23.7401 7.37046 25.0179 8.64821C26.2956 9.92599 27.3063 11.4239 28.0499 13.1418C28.7935 14.8597 29.1653 16.7016 29.1653 18.6674C29.1653 20.6332 28.7935 22.4751 28.0499 24.193C27.3063 25.911 26.2956 27.4089 25.0179 28.6866C23.7401 29.9644 22.2422 30.975 20.5243 31.7186C18.8063 32.4622 16.9645 32.834 14.9987 32.834ZM10.3512 23.411V15.911H7.94741V13.9238H12.3384V23.411H10.3512ZM15.9922 23.411C15.52 23.411 15.1242 23.2513 14.8047 22.9318C14.4853 22.6124 14.3256 22.2166 14.3256 21.7443V15.5905C14.3256 15.1183 14.4853 14.7224 14.8047 14.403C15.1242 14.0836 15.52 13.9238 15.9922 13.9238H19.1333C19.6055 13.9238 20.0013 14.0836 20.3208 14.403C20.6402 14.7224 20.7999 15.1183 20.7999 15.5905V21.7443C20.7999 22.2166 20.6402 22.6124 20.3208 22.9318C20.0013 23.2513 19.6055 23.411 19.1333 23.411H15.9922ZM16.5051 21.4238H18.6205C18.6846 21.4238 18.7326 21.4078 18.7647 21.3758C18.7968 21.3437 18.8128 21.2956 18.8128 21.2315V16.1033C18.8128 16.0392 18.7968 15.9911 18.7647 15.9591C18.7326 15.927 18.6846 15.911 18.6205 15.911H16.5051C16.441 15.911 16.3929 15.927 16.3608 15.9591C16.3288 15.9911 16.3127 16.0392 16.3127 16.1033V21.2315C16.3127 21.2956 16.3288 21.3437 16.3608 21.3758C16.3929 21.4078 16.441 21.4238 16.5051 21.4238Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
              {debug === "enabled" ? (
                <>
                  <button
                    disabled={scrub === 0}
                    onClick={() => setScrub(scrub - 1)}
                    style={{ width: 40, margin: "0 16px" }}
                    title="Click to scrub 1 second backward"
                  >
                    -1
                  </button>
                  <button
                    disabled={scrub === 0}
                    onClick={() => setScrub(scrub - 0.1)}
                    style={{ width: 40 }}
                    title="Click to scrub 1 frame backward"
                  >
                    -0.1
                  </button>
                </>
              ) : null}
              <button
                disabled={scrub >= 300}
                onClick={() => setPaused(!paused)}
                style={{ height: 40, margin: "0 36px" }}
                title={
                  paused
                    ? "Click to play from the scrub position"
                    : "Click to pause at the scrub position"
                }
              >
                {paused ? (
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 29L29 20L15 11V29ZM20 40C17.2333 40 14.6333 39.475 12.2 38.425C9.76667 37.375 7.65 35.95 5.85 34.15C4.05 32.35 2.625 30.2333 1.575 27.8C0.525 25.3667 0 22.7667 0 20C0 17.2333 0.525 14.6333 1.575 12.2C2.625 9.76667 4.05 7.65 5.85 5.85C7.65 4.05 9.76667 2.625 12.2 1.575C14.6333 0.525 17.2333 0 20 0C22.7667 0 25.3667 0.525 27.8 1.575C30.2333 2.625 32.35 4.05 34.15 5.85C35.95 7.65 37.375 9.76667 38.425 12.2C39.475 14.6333 40 17.2333 40 20C40 22.7667 39.475 25.3667 38.425 27.8C37.375 30.2333 35.95 32.35 34.15 34.15C32.35 35.95 30.2333 37.375 27.8 38.425C25.3667 39.475 22.7667 40 20 40Z"
                      fill="currentColor"
                    />
                  </svg>
                ) : (
                  <img src={PauseIcon} alt="Pause" width={40} height={40} />
                )}
              </button>
              {debug === "enabled" ? (
                <>
                  <button
                    disabled={scrub >= 300}
                    onClick={() => setScrub(scrub + 0.1)}
                    style={{ width: 40 }}
                    title="Click to scrub 1 frame forward"
                  >
                    +0.1
                  </button>
                  <button
                    disabled={scrub >= 300}
                    onClick={() => setScrub(scrub + 1)}
                    style={{ width: 40, margin: "0 16px" }}
                    title="Click to scrub 1 second forward"
                  >
                    +1
                  </button>
                </>
              ) : null}
              <button
                disabled={scrub >= 300}
                onClick={() => setScrub(scrub + 10)}
                style={{ height: 33 }}
                title="Click to scrub 10 seconds forward"
              >
                <svg
                  width="30"
                  height="33"
                  viewBox="0 0 30 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.3512 23.411V15.911H7.94741V13.9238H12.3384V23.411H10.3512ZM15.9922 23.411C15.52 23.411 15.1242 23.2513 14.8047 22.9318C14.4853 22.6124 14.3256 22.2166 14.3256 21.7443V15.5905C14.3256 15.1183 14.4853 14.7224 14.8047 14.403C15.1242 14.0836 15.52 13.9238 15.9922 13.9238H19.1333C19.6055 13.9238 20.0013 14.0836 20.3208 14.403C20.6402 14.7224 20.7999 15.1183 20.7999 15.5905V21.7443C20.7999 22.2166 20.6402 22.6124 20.3208 22.9318C20.0013 23.2513 19.6055 23.411 19.1333 23.411H15.9922ZM16.5051 21.4238H18.6205C18.6846 21.4238 18.7326 21.4078 18.7647 21.3758C18.7968 21.3437 18.8128 21.2956 18.8128 21.2315V16.1033C18.8128 16.0392 18.7968 15.9911 18.7647 15.9591C18.7326 15.927 18.6846 15.911 18.6205 15.911H16.5051C16.441 15.911 16.3929 15.927 16.3608 15.9591C16.3288 15.9911 16.3127 16.0392 16.3127 16.1033V21.2315C16.3127 21.2956 16.3288 21.3437 16.3608 21.3758C16.3929 21.4078 16.441 21.4238 16.5051 21.4238ZM14.9987 32.834C13.0329 32.834 11.191 32.4622 9.47303 31.7186C7.75509 30.975 6.25723 29.9644 4.97945 28.6866C3.7017 27.4089 2.69103 25.911 1.94745 24.193C1.20384 22.4751 0.832031 20.6332 0.832031 18.6674C0.832031 16.7016 1.20384 14.8597 1.94745 13.1418C2.69103 11.4239 3.7017 9.92599 4.97945 8.64821C6.25723 7.37046 7.75509 6.3598 9.47303 5.61621C11.191 4.8726 13.0329 4.5008 14.9987 4.5008H15.441L12.7615 1.82134L14.5179 0.0136719L20.2549 5.73476L14.5499 11.4558L12.7935 9.64821L15.441 7.00076H14.9987C11.7487 7.00076 8.99171 8.1327 6.72782 10.3966C4.46393 12.6605 3.33199 15.4174 3.33199 18.6674C3.33199 21.9174 4.46393 24.6744 6.72782 26.9383C8.99171 29.2021 11.7487 30.3341 14.9987 30.3341C18.2487 30.3341 21.0056 29.2021 23.2695 26.9383C25.5334 24.6744 26.6653 21.9174 26.6653 18.6674H29.1653C29.1653 20.6332 28.7935 22.4751 28.0499 24.193C27.3063 25.911 26.2956 27.4089 25.0179 28.6866C23.7401 29.9644 22.2422 30.975 20.5243 31.7186C18.8063 32.4622 16.9645 32.834 14.9987 32.834Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      ) : null}
      <Source type="geojson" data={laneSurfaces}>
        <Layer
          id="laneSurfaces"
          type="fill"
          layout={{
            visibility: showLaneSurfaces ? "visible" : "none",
          }}
          minzoom={15}
          paint={{
            "fill-color": {
              property: "laneType",
              type: "categorical",
              stops: [
                ["ingress", "#ffafd2"],
                ["egress", "#d4bbff"],
                ["other", "#8d8d8d"],
              ],
            },
            "fill-opacity": {
              property: "highlighted",
              type: "categorical",
              stops: [
                [true, 1],
                [false, 0.75],
              ],
            },
            "fill-outline-color": {
              property: "laneType",
              type: "categorical",
              stops: [
                ["ingress", "#ee5396"],
                ["egress", "#a56eff"],
                ["other", "#525252"],
              ],
            },
          }}
        />
      </Source>
      <Source type="geojson" data={laneCenterlines}>
        <Layer
          id="laneCenterlines"
          type="line"
          layout={{
            visibility: showLaneCenterlines ? "visible" : "none",
          }}
          minzoom={15}
          paint={{
            "line-color": {
              property: "laneType",
              type: "categorical",
              stops: [
                ["ingress", "#ee5396"],
                ["egress", "#a56eff"],
                ["other", "#525252"],
              ],
            },
            "line-dasharray": [1, 1],
          }}
        />
      </Source>
      <Source type="geojson" data={movementSurfaces}>
        <Layer
          id="movementSurfaces"
          type="fill"
          layout={{
            visibility: showMovements ? "visible" : "none",
          }}
          filter={["!=", "eventState", "unavailable"]}
          minzoom={15}
          paint={{
            "fill-color": {
              property: "highlighted",
              type: "categorical",
              stops: [
                [true, "#6f6f6f"],
                [false, "#525252"],
              ],
            },
            "fill-opacity": {
              property: "highlighted",
              type: "categorical",
              stops: [
                [true, 1],
                [false, 0.75],
              ],
            },
          }}
        />
      </Source>
      <Source type="geojson" data={movementCenterlines}>
        <Layer
          id="movementCenterlines"
          type="line"
          layout={{
            visibility: showMovements ? "visible" : "none",
          }}
          filter={["!=", "eventState", "unavailable"]}
          minzoom={15}
          paint={{
            "line-color": {
              property: "eventState",
              type: "categorical",
              stops: [
                ["permissive-clearance", "#fec84b"],
                ["permissive-Movement-Allowed", "#24a148"],
                ["protected-clearance", "#fec84b"],
                ["protected-Movement-Allowed", "#24a148"],
                ["dark", "#000000"],
                ["stop-Then-Proceed", "#fa4d56"],
                ["stop-And-Remain", "#fa4d56"],
                ["pre-Movement", "#24a148"],
                ["caution-Conflicting-Traffic", "#fec84b"],
              ],
            },
            "line-dasharray": {
              property: "eventState",
              type: "categorical",
              stops: [
                ["permissive-clearance", [4, 4]],
                ["permissive-Movement-Allowed", [1, 1]],
                ["protected-clearance", [1]],
                ["protected-Movement-Allowed", [1]],
                ["dark", [1]],
                ["stop-Then-Proceed", [6, 6]],
                ["stop-And-Remain", [1]],
                ["pre-Movement", [4, 4]],
                ["caution-Conflicting-Traffic", [6, 6]],
              ],
            } as any,
            "line-opacity": {
              property: "eventState",
              type: "categorical",
              stops: [["stop-And-Remain", 0.25]],
            },
            "line-width": {
              property: "eventState",
              type: "categorical",
              stops: [
                ["permissive-clearance", 2],
                ["permissive-Movement-Allowed", 2],
                ["protected-clearance", 2],
                ["protected-Movement-Allowed", 2],
                ["dark", 4],
                ["stop-Then-Proceed", 3],
                ["stop-And-Remain", 3],
                ["pre-Movement", 2],
                ["caution-Conflicting-Traffic", 2],
              ],
            },
          }}
        />
      </Source>
      <Source type="geojson" data={mapReferencePoints}>
        <Layer
          id="mapReferencePoints"
          type="circle"
          layout={{
            visibility:
              showMapReferencePoints && debug === "enabled"
                ? "visible"
                : "none",
          }}
          minzoom={15}
        />
      </Source>
      <Source type="geojson" data={vehicleTelemetry}>
        <Layer
          id="basicSafetyMessagesBg"
          type="circle"
          layout={{
            visibility: showBasicSafetyMessages ? "visible" : "none",
          }}
          filter={["all", ["==", "messageType", "BSM"], ["<=", "time", now]]}
          minzoom={15}
          paint={{
            "circle-color": [
              "interpolate",
              ["linear"],
              ["get", "age"],
              0,
              "#007d79",
              300,
              "#66e3df",
            ],
            "circle-stroke-color": [
              "interpolate",
              ["linear"],
              ["get", "age"],
              0,
              "#08bdba",
              300,
              "#6effff",
            ],
            "circle-stroke-width": 1,
            "circle-radius": {
              property: "highlighted",
              type: "categorical",
              stops: [
                [true, 11.25],
                [false, 7.5],
              ],
            },
          }}
        />
        <Layer
          id="signalRequestMessagesRequest"
          type="circle"
          layout={{
            visibility: showSignalRequestMessages ? "visible" : "none",
          }}
          filter={[
            "all",
            ["==", "messageType", "SRM"],
            ["==", "ciptState", "Request"],
            ["<=", "time", now],
          ]}
          minzoom={15}
          paint={{
            "circle-color": [
              "interpolate",
              ["linear"],
              ["get", "age"],
              0,
              "#11642a",
              300,
              "#a6f0ba",
            ],
            "circle-stroke-color": [
              "interpolate",
              ["linear"],
              ["get", "age"],
              0,
              "#1d833c",
              300,
              "#a6f0ba",
            ],
            "circle-stroke-width": 1,
            "circle-radius": {
              property: "highlighted",
              type: "categorical",
              stops: [
                [true, 11.25],
                [false, 7.5],
              ],
            },
          }}
        />
        <Layer
          id="signalRequestMessagesUpdate"
          type="circle"
          layout={{
            visibility: showSignalRequestMessages ? "visible" : "none",
          }}
          filter={[
            "all",
            ["==", "messageType", "SRM"],
            ["==", "ciptState", "Update"],
            ["<=", "time", now],
          ]}
          minzoom={15}
          paint={{
            "circle-color": [
              "interpolate",
              ["linear"],
              ["get", "age"],
              0,
              "#f7920b",
              300,
              "#fec84b",
            ],
            "circle-stroke-color": [
              "interpolate",
              ["linear"],
              ["get", "age"],
              0,
              "#fdb226",
              300,
              "#fedf88",
            ],
            "circle-stroke-width": 1,
            "circle-radius": {
              property: "highlighted",
              type: "categorical",
              stops: [
                [true, 11.25],
                [false, 7.5],
              ],
            },
          }}
        />
        <Layer
          id="signalRequestMessagesCancel"
          type="circle"
          layout={{
            visibility: showSignalRequestMessages ? "visible" : "none",
          }}
          filter={[
            "all",
            ["==", "messageType", "SRM"],
            ["==", "ciptState", "Cancel"],
            ["<=", "time", now],
          ]}
          minzoom={15}
          paint={{
            "circle-color": [
              "interpolate",
              ["linear"],
              ["get", "age"],
              0,
              "#a51b21",
              300,
              "#f94d56",
            ],
            "circle-stroke-color": [
              "interpolate",
              ["linear"],
              ["get", "age"],
              0,
              "#db212b",
              300,
              "#ff8288",
            ],
            "circle-stroke-width": 1,
            "circle-radius": {
              property: "highlighted",
              type: "categorical",
              stops: [
                [true, 11.25],
                [false, 7.5],
              ],
            },
          }}
        />
        <Layer
          id="basicSafetyMessagesIcon"
          type="symbol"
          layout={{
            "icon-allow-overlap": true,
            "icon-image": {
              property: "highlighted",
              type: "categorical",
              stops: [
                [true, "chevronLarge"],
                [false, "chevronSmall"],
              ],
            },
            "icon-rotate": ["get", "heading"],
            visibility: showBasicSafetyMessages ? "visible" : "none",
          }}
          filter={["all", ["==", "messageType", "BSM"], ["<=", "time", now]]}
          minzoom={15}
        />
        <Layer
          id="signalRequestMessagesIcon"
          type="symbol"
          layout={{
            "icon-allow-overlap": true,
            "icon-image": {
              property: "highlighted",
              type: "categorical",
              stops: [
                [true, "chevronLarge"],
                [false, "chevronSmall"],
              ],
            },
            "icon-rotate": ["get", "heading"],
            visibility: showSignalRequestMessages ? "visible" : "none",
          }}
          filter={["all", ["==", "messageType", "SRM"], ["<=", "time", now]]}
          minzoom={15}
        />
      </Source>
      <Source
        type="geojson"
        data={{
          type: "FeatureCollection",
          features: sites
            .filter((site) => debug !== "enabled" || site.type === "Standalone")
            .map((site) => ({
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [site.lon, site.lat],
              },
              properties: {
                sourceId: site.id,
                highlighted: (site as any).highlighted,
              },
            })),
        }}
      >
        <Layer
          id="standardSites"
          type="circle"
          layout={{
            visibility:
              showSitePositions && debug === "enabled" ? "visible" : "none",
          }}
          paint={{
            "circle-color": {
              property: "highlighted",
              type: "categorical",
              stops: [
                [true, "#ffffff"],
                [false, "#000000"],
              ],
            },
            "circle-radius": {
              property: "highlighted",
              type: "categorical",
              stops: [
                [true, 24],
                [false, 16],
              ],
            },
          }}
        />
      </Source>
      <Source
        type="geojson"
        data={{
          type: "FeatureCollection",
          features: sites
            .filter(
              (site) => debug !== "enabled" || site.type === "Intersection"
            )
            .map((site) => ({
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [site.lon, site.lat],
              },
              properties: {
                sourceId: site.id,
                highlighted: (site as any).highlighted,
              },
            })),
        }}
      >
        <Layer
          id="intersectionSites"
          type="symbol"
          layout={{
            "icon-allow-overlap": true,
            "icon-image": {
              property: "highlighted",
              type: "categorical",
              stops: [
                [true, "siteLarge"],
                [false, "siteSmall"],
              ],
            },
            visibility:
              showSitePositions || debug !== "enabled" ? "visible" : "none",
          }}
        />
      </Source>
    </Map>
  );
};
export default MapWrapper;
