import React from "react";

import Modal from "../Modal";
import Button from "../controls/Button";
import Input from "../controls/Input";
import Radio from "../controls/Radio";


import { generateRandomString } from "../../lib/generate";

import DeviceModal from "./DeviceModal";

import { FixedDevice, NormalizedSite } from "../../lib/devices/deviceTypes";


const SiteModal = ({
  confirmAction,
  headerText,
  intersectionIdsAndRegions,
  site: prevSite,
  siteNames,
}: {
  confirmAction: (site: NormalizedSite) => void;
  headerText: string;
  intersectionIdsAndRegions: string[];
  site?: NormalizedSite;
  siteNames: string[];
}) => {
  const prevSiteName = prevSite?.name ?? "";
  const prevSiteCity = prevSite?.city ?? "";
  const prevSiteType = prevSite?.type ?? "Standalone";
  const prevIntersectionId = prevSite?.intersectionId ?? "";
  const prevIntersectionRegion = prevSite?.roadRegulatorId ?? "";
  const prevLatitude = prevSite?.lat ?? "";
  const prevLongitude = prevSite?.lon ?? "";

  const [nextSiteName, setNextSiteName] = React.useState(prevSiteName);
  const [nextSiteCity, setNextSiteCity] = React.useState(prevSiteCity);
  const [nextSiteType, setNextSiteType] = React.useState(prevSiteType);
  const [nextIntersectionId, setNextIntersectionId] =
    React.useState(prevIntersectionId);
  const [nextIntersectionRegion, setNextIntersectionRegion] = React.useState(
    prevIntersectionRegion
  );
  const [nextLatitude, setNextLatitude] = React.useState(prevLatitude);
  const [nextLongitude, setNextLongitude] = React.useState(prevLongitude);




  const siteNameError = !nextSiteName.trim().length
    ? "*Site Name is required"
    : prevSite?.name !== nextSiteName && siteNames.includes(nextSiteName)
      ? "Site Name is already taken"
      : "";

  let prevSiteIntersectionIDAndRegion = "";
  let nextSiteIntersectionIdAndRegion = "";
  let intersectionError = "";

  if (prevSite?.type === "Intersection") {
    if (prevSite.intersectionId !== null) {
      prevSiteIntersectionIDAndRegion += prevSite.intersectionId;

      if (prevSite.roadRegulatorId !== null) {
        prevSiteIntersectionIDAndRegion += `:${prevSite.roadRegulatorId}`;
      }
    }
  }

  if (nextSiteType === "Intersection") {
    if (nextIntersectionId === "") {
      intersectionError = "*Intersection ID is required";
    } else {
      nextSiteIntersectionIdAndRegion += nextIntersectionId;

      if (nextIntersectionRegion !== "") {
        nextSiteIntersectionIdAndRegion += `:${nextIntersectionRegion}`;
      }

      if (
        prevSiteIntersectionIDAndRegion !== nextSiteIntersectionIdAndRegion &&
        intersectionIdsAndRegions.includes(nextSiteIntersectionIdAndRegion)
      ) {
        intersectionError =
          "Intersection ID + Intersection Region combination is already taken";
      }
    }
  }
  const latitudeError = nextLatitude === "" ? "*Latitude is required" : "";
  const longitudeError = nextLongitude === "" ? "*Longitude is required" : "";

  return (
    <Modal
      className="light"
      footer={
        <Button
          className="action lg"
          disabled={
            !!siteNameError ||
            !!intersectionError ||
            !!latitudeError ||
            !!longitudeError ||
            (prevSiteName === nextSiteName &&
              prevSiteCity === nextSiteCity &&
              prevSiteType === nextSiteType &&
              prevIntersectionId === nextIntersectionId &&
              prevIntersectionRegion === nextIntersectionRegion &&
              prevLatitude === nextLatitude &&
              prevLongitude === nextLongitude)
          }
          onClick={() =>
            confirmAction({
              deviceIds: prevSite?.deviceIds ?? [],
              id: prevSite?.id ?? generateRandomString(),
              intersectionId:
                nextSiteType === "Intersection"
                  ? parseInt(nextIntersectionId as string, 10)
                  : null,
              lat: nextLatitude as number,
              lon: nextLongitude as number,
              name: nextSiteName,
              city: nextSiteCity,
              roadRegulatorId:
                nextSiteType === "Intersection" &&
                  typeof nextIntersectionRegion === "number"
                  ? nextIntersectionRegion
                  : null,
              siteMetadata: {
                gateway: null,
                project_name: null,
                road_regulator_id:
                  nextSiteType === "Intersection" &&
                    typeof nextIntersectionRegion === "number"
                    ? nextIntersectionRegion
                    : null,
                subnet: null,
              } as any,
              type: nextSiteType,
            })
          }
          style={{ marginTop: 32 }}
        >
          CONFIRM
        </Button>
      }
      header={<h2>{headerText}</h2>}
    >
      <div
        style={{ width: "100%", height: 80, marginTop: 6, marginBottom: 24 }}
      >
        <Input
          autoFocus
          changed={!headerText.includes("New") && prevSiteName !== nextSiteName}
          id="site-name"
          label="Site Name"
          maxLength={200}
          onChange={(e) => setNextSiteName(e.target.value.slice(0, 200))}
          placeholder={
            nextSiteType === "Intersection"
              ? "Main St & 1st Avenue"
              : "Hwy 100 MM 123"
          }
          required
          type="text"
          validate={() => siteNameError}
          value={nextSiteName}
        />
      </div>
      <div style={{ width: 840, height: 64, marginBottom: 24 }}>
        <Radio
          changed={!headerText.includes("New") && prevSiteType !== nextSiteType}
          label="Site Type"
          name="site-type"
          onChange={(type) => setNextSiteType(type as any)}
          options={[
            {
              value: "Standalone",
            },
            {
              value: "Intersection",
            },
          ]}
          required
          value={nextSiteType}
        />
      </div>
      {nextSiteType === "Intersection" ? (
        <>
          <div style={{ width: "100%", height: 80, marginBottom: 24 }}>
            <Input
              changed={
                !headerText.includes("New") &&
                prevIntersectionId !== nextIntersectionId
              }
              id="intersection-id"
              label="Intersection ID"
              max={65535}
              min={0}
              onChange={(e) => {
                const parsed = parseInt(e.target.value, 10);

                setNextIntersectionId(
                  Number.isNaN(parsed)
                    ? ("" as any)
                    : Math.min(65535, Math.max(0, parsed))
                );
              }}
              placeholder="e.g. 100"
              required
              type="number"
              validate={() => intersectionError}
              value={nextIntersectionId}
            />
          </div>
          <div style={{ width: "100%", height: 80, marginBottom: 24 }}>
            <Input
              changed={
                !headerText.includes("New") &&
                prevIntersectionRegion !== nextIntersectionRegion
              }
              id="intersection-region"
              label="Intersection Region"
              max={65535}
              min={0}
              onChange={(e) => {
                const parsed = parseInt(e.target.value, 10);

                setNextIntersectionRegion(
                  Number.isNaN(parsed)
                    ? ("" as any)
                    : Math.min(65535, Math.max(0, parsed))
                );
              }}
              placeholder="e.g. 1"
              type="number"
              value={nextIntersectionRegion}
            />
          </div>
        </>
      ) : null}
      <div style={{ width: "100%", height: 80, marginBottom: 24 }}>
        <Input
          changed={!headerText.includes("New") && prevLatitude !== nextLatitude}
          id="latitude"
          label="Latitude"
          max={90}
          min={-90}
          onChange={(e) => {
            const parsed = parseFloat(e.target.value);
            const nextValue =
              e.target.value === "-"
                ? "-"
                : Math.min(90, Math.max(-90, parseFloat(parsed.toFixed(8))));

            setNextLatitude(
              e.target.value !== "-" && Number.isNaN(parsed)
                ? ("" as any)
                : nextValue
            );
          }}
          placeholder="e.g. 39.80894"
          required
          step={0.000001}
          type="number"
          value={nextLatitude}
        />
      </div>
      <div style={{ width: "100%", height: 80, marginBottom: 24 }}>
        <Input
          changed={
            !headerText.includes("New") && prevLongitude !== nextLongitude
          }
          id="longitude"
          label="Longitude"
          max={180}
          min={-180}
          onChange={(e) => {
            const parsed = parseFloat(e.target.value);
            const nextValue =
              e.target.value === "-"
                ? "-"
                : Math.min(180, Math.max(-180, parseFloat(parsed.toFixed(8))));

            setNextLongitude(
              e.target.value !== "-" && Number.isNaN(parsed)
                ? ("" as any)
                : nextValue
            );
          }}
          placeholder="e.g. -104.78054"
          required
          step={0.000001}
          type="number"
          value={nextLongitude}
        />
      </div>
      <div style={{ width: "100%", height: 80, marginBottom: 24 }}>
        <Input
          changed={!headerText.includes("New") && prevSiteCity !== nextSiteCity}
          id="site-city"
          label="Site City"
          maxLength={200}
          onChange={(e) =>
            setNextSiteCity(e.target.value.slice(0, 200).toUpperCase())
          }
          placeholder="DENVER"
          type="text"
          value={nextSiteCity}
        />
      </div>
    </Modal>
  );
};


export const RegisterNewSiteModal = ({
  confirmAction,
  intersectionIdsAndRegions,
  siteNames,
}: {
  confirmAction: (site: NormalizedSite) => void;
  intersectionIdsAndRegions: string[];
  siteNames: string[];
}) => (
  <SiteModal
    confirmAction={confirmAction}
    headerText="Register New Site"
    intersectionIdsAndRegions={intersectionIdsAndRegions}
    siteNames={siteNames}
  />
);

export const EditSiteModal = ({
  confirmAction,
  intersectionIdsAndRegions,
  site,
  siteNames,
}: {
  confirmAction: (site: NormalizedSite) => void;
  intersectionIdsAndRegions: string[];
  site: NormalizedSite;
  siteNames: string[];
}) => (
  <SiteModal
    confirmAction={confirmAction}
    headerText="Edit Site"
    intersectionIdsAndRegions={intersectionIdsAndRegions}
    site={site}
    siteNames={siteNames}
  />
);

export const RegisterNewDeviceModal = ({
  confirmAction,
  enrolledMakeModelSerialNumbers,
  ipAddresses,
  makeModelSerialNumbers,
  site,
}: {
  confirmAction: (device: FixedDevice) => void;
  enrolledMakeModelSerialNumbers: string[];
  ipAddresses: string[];
  makeModelSerialNumbers: string[];
  site: NormalizedSite;
}) => (
  <DeviceModal
    confirmAction={confirmAction}
    enrolledMakeModelSerialNumbers={enrolledMakeModelSerialNumbers}
    headerText="Register New Device"
    ipAddresses={ipAddresses}
    makeModelSerialNumbers={makeModelSerialNumbers}
    site={site}
  />
);

export const EditDeviceModal = ({
  confirmAction,
  device,
  enrolledMakeModelSerialNumbers,
  ipAddresses,
  makeModelSerialNumbers,
  site,
}: {
  confirmAction: (device: FixedDevice) => void;
  device: FixedDevice;
  enrolledMakeModelSerialNumbers: string[];
  ipAddresses: string[];
  makeModelSerialNumbers: string[];
  site: NormalizedSite;
}) => (
  <DeviceModal
    confirmAction={confirmAction}
    device={device}
    enrolledMakeModelSerialNumbers={enrolledMakeModelSerialNumbers}
    headerText="Edit Device"
    ipAddresses={ipAddresses}
    makeModelSerialNumbers={makeModelSerialNumbers}
    site={site}
  />
);

export const RemoveDeviceModal = ({
  confirmAction,
  device,
}: {
  confirmAction: (device: FixedDevice) => void;
  device: FixedDevice;
}) => (
  <Modal
    className="light"
    footer={
      <Button className="action lg" onClick={() => confirmAction(device)}>
        CONFIRM
      </Button>
    }
    header={
      <h2 style={{ fontSize: "24px" }}>
        Are you sure you want to remove this Device?
      </h2>
    }
  >
    <p style={{ marginBottom: 42, fontSize: "16px", color: "#161616" }}>
      This action cannot be undone. Once a Device has been removed from a Site,
      it will need to be registered again from the Site Detail view.
    </p>
  </Modal>
);
