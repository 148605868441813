import dayjs from "dayjs";

export const epochToDate = (msEpoch: number, leadingZeroes = true) => {
  const dayObj = dayjs(msEpoch);

  return [
    leadingZeroes ? zeroPad(dayObj.month() + 1) : dayObj.month() + 1,
    leadingZeroes ? zeroPad(dayObj.date()) : dayObj.date(),
    dayObj.year(),
  ].join("/");
};

export const epochToTime = (
  msEpoch: number,
  decimalPlaces = 0,
  leadingZeroes = true
) => {
  const is24h = !!localStorage.getItem("24h");
  const dayObj = dayjs(msEpoch);
  let hour = dayObj.hour();
  const pm = dayObj.hour() >= 12;

  if (!is24h) {
    if (hour >= 13) {
      hour -= 12;
    } else if (hour === 0) {
      hour = 12;
    }
  }
  let time = [
    leadingZeroes || is24h ? zeroPad(hour) : hour,
    zeroPad(dayObj.minute()),
    `${zeroPad(dayObj.second())}${
      decimalPlaces
        ? `.${msEpoch.toString().slice(-3).slice(0, decimalPlaces)}`
        : ""
    }`,
  ].join(":");

  if (!is24h) {
    time += pm ? " PM" : " AM";
  }
  return time;
};

export const fromStringToDayjs = (fromString: string) => {
  const [year, month, day, hour, minute, utcOffset] = fromString.split("_");
  const utcOffsetHour = utcOffset.slice(0, 3);
  const utcOffsetMinute = utcOffset.slice(3, 5);

  return dayjs(
    `${year}-${month}-${day}T${hour}:${minute}:00${utcOffsetHour}:${utcOffsetMinute}`
  );
};

export const fromStringToEpoch = (fromString: string) =>
  fromStringToDayjs(fromString).unix();

export const kphToMph = (kph: number) => kph * 0.621371;

export const mpsToKph = (mps: number) => mps * 3.6;

export const mpsToMph = (mps: number) => kphToMph(mpsToKph(mps));

export const zeroPad = (num: number) => (num < 10 ? `0${num}` : num.toFixed());
