import React, { useMemo, useState } from "react";
import {
  deviceSupportsSnmpV3,
  deviceSupportsSsh,
} from "../../lib/devices/deviceUtils";
import { getDeviceTypeLabel } from "../../lib/v2x/v2xUtils";
import {
  validateIpAddress,
  validatePasswordComplexity,
} from "../../lib/validation";
import Modal from "../Modal";
import Button from "../controls/Button";
import Input from "../controls/Input";
import Radio from "../controls/Radio";
import Toggle from "../controls/Toggle";
import { generateRandomString } from "../../lib/generate";
import { FixedDevice, NormalizedSite } from "../../lib/devices/deviceTypes";


const DeviceModal = ({
  confirmAction,
  enrolledMakeModelSerialNumbers,
  headerText,
  ipAddresses,
  makeModelSerialNumbers,
  device: prevDevice,
  site,
}: {
  confirmAction: (device: FixedDevice) => void;
  enrolledMakeModelSerialNumbers: string[];
  headerText: string;
  ipAddresses: string[];
  makeModelSerialNumbers: string[];
  device?: FixedDevice;
  site: NormalizedSite;
}) => {
  const parsedMetadata = {
    managed_by: null,
    ping: false,
    snmp: false,
    ssh: false,
    ...(prevDevice?.deviceMetadata
      ? JSON.parse(prevDevice?.deviceMetadata)
      : {}),
  };
  const parsedSecrets = {
    snmp_auth_password: null,
    snmp_priv_password: null,
    snmp_port: null,
    snmp_username: null,
    ssh_password: null,
    ssh_username: null,
    ...(prevDevice?.deviceSecrets ? JSON.parse(prevDevice?.deviceSecrets) : {}),
  };

  const prevDeviceType = prevDevice?.deviceType ?? "RSU";
  const prevIpAddress = prevDevice?.ipAddress ?? "";
  const prevSerialNumber = prevDevice?.serialNumber ?? "";
  const prevMake = prevDevice?.make ?? "";
  const prevModel = prevDevice?.model ?? "";
  const prevEnrolled = prevDevice?.enrolled ?? true;
  const prevPingEnabled = parsedMetadata.ping;

  const prevSshEnabled = parsedMetadata.ssh;
  const prevSshUsername = parsedSecrets.ssh_username ?? "";
  const prevSshPassword = parsedSecrets.ssh_password ?? "";

  const prevSnmpV3Enabled = parsedMetadata.snmp;
  const prevSnmpV3Username = parsedSecrets.snmp_username ?? "";
  const prevSnmpV3SecurityLevel = parsedSecrets.snmp_level ?? null;
  const prevSnmpV3AuthenticationProtocol = parsedSecrets.snmp_auth_protocol ?? null;
  const prevSnmpV3PrivacyProtocol = parsedSecrets.snmp_priv_protocol ?? null;
  const prevSnmpV3AuthPassphrase = parsedSecrets.snmp_auth_password ?? "";
  const prevSnmpV3PrivPassphrase = parsedSecrets.snmp_priv_password ?? null;
  const prevSnmpV3Port = parsedSecrets.snmp_port ?? 161;

  const prevMakeModelSerialNumber =
    typeof prevMake === "string" &&
      typeof prevModel === "string" &&
      typeof prevSerialNumber === "string"
      ? `${[prevDeviceType, prevMake, prevModel]
        .join(",")
        .toUpperCase()},${prevSerialNumber}`
      : null;

  const [nextDeviceType, setNextDeviceType] = useState(prevDeviceType);
  const [nextIpAddress, setNextIpAddress] = useState(prevIpAddress);
  const [nextSerialNumber, setNextSerialNumber] =
    useState(prevSerialNumber);
  const [nextMake, setNextMake] = useState(prevMake);
  const [nextModel, setNextModel] = useState(prevModel);
  const [nextEnrolled, setNextEnrolled] = useState(prevEnrolled);
  const [nextPingEnabled, setNextPingEnabled] = useState(prevPingEnabled);
  const [nextSnmpV3Enabled, setNextSnmpV3Enabled] = useState<boolean | undefined>(prevSnmpV3Enabled);
  const [nextSnmpV3Username, setNextSnmpV3Username] =
    useState(prevSnmpV3Username);
  const [nextSnmpV3AuthPassphrase, setNextSnmpV3AuthPassphrase] =
    useState(prevSnmpV3AuthPassphrase);
  const [nextSnmpV3PrivPassphrase, setNextSnmpV3PrivPassphrase] = useState<string | null>(prevSnmpV3PrivPassphrase);
  const [nextSnmpV3SecurityLevel, setNextSnmpV3SecurityLevel] = useState<SNMPv3_SECURITY_LEVEL | null>(prevSnmpV3SecurityLevel);
  const handleSNMPv3SecurityLevelChange = (level: string) => {
    setNextSnmpV3SecurityLevel(level as SNMPv3_SECURITY_LEVEL)
  }
  const [nextSnmpV3AuthenticationProtocol, setNextSnmpV3AuthenticationProtocol] = useState<SNMPv3_AUTHENTICATION_PROTOCOL | null>(prevSnmpV3AuthenticationProtocol);
  const handleSNMPv3AuthenticationProtocolChange = (protocol: string) => {
    setNextSnmpV3AuthenticationProtocol(protocol as SNMPv3_AUTHENTICATION_PROTOCOL)
  }
  const [nextSnmpV3PrivacyProtocol, setNextSnmpV3PrivacyProtocol] = useState<SNMPv3_PRIVACY_PROTOCOL | null>(prevSnmpV3PrivacyProtocol);
  const handleSNMPv3PrivacyProtocolChange = (protocol: string) => {
    setNextSnmpV3PrivacyProtocol(protocol as SNMPv3_PRIVACY_PROTOCOL)
  }
  const [nextSnmpV3Port, setNextSnmpV3Port] = useState<number>(prevSnmpV3Port);



  const [nextSshEnabled, setNextSshEnabled] = useState(prevSshEnabled);
  const [nextSshUsername, setNextSshUsername] = useState(prevSshUsername);
  const [nextSshPassword, setNextSshPassword] = useState(prevSshPassword);




  const SNMPv3_AUTHENTICATION_PROTOCOL = ["MD5", "SHA", "SHA-224", "SHA-256", "SHA-384", "SHA-512"] as const;
  // eslint-disable-next-line @typescript-eslint/no-redeclare -- intentionally naming the variable the same as the type
  type SNMPv3_AUTHENTICATION_PROTOCOL = typeof SNMPv3_AUTHENTICATION_PROTOCOL[number];

  const SNMPv3_PRIVACY_PROTOCOL = ["DES", "AES", "AES-192", "AES-256"] as const;
  // eslint-disable-next-line @typescript-eslint/no-redeclare -- intentionally naming the variable the same as the type
  type SNMPv3_PRIVACY_PROTOCOL = typeof SNMPv3_PRIVACY_PROTOCOL[number];

  const SNMPv3_SECURITY_LEVEL = ["authPriv", "authNoPriv", "noAuthNoPriv"] as const;
  // eslint-disable-next-line @typescript-eslint/no-redeclare -- intentionally naming the variable the same as the type
  type SNMPv3_SECURITY_LEVEL = typeof SNMPv3_SECURITY_LEVEL[number];


  const nextMakeModelSerialNumber =
    typeof nextMake === "string" &&
      typeof nextModel === "string" &&
      typeof nextSerialNumber === "string"
      ? `${[nextDeviceType, nextMake, nextModel]
        .join(",")
        .toUpperCase()},${nextSerialNumber}`
      : null;

  const ipAddressError = !nextIpAddress.length
    ? "*IP Address is required"
    : !validateIpAddress(nextIpAddress)
      ? "IP Address format is invalid"
      : (!prevEnrolled || prevIpAddress !== nextIpAddress) &&
        ipAddresses.includes(nextIpAddress)
        ? "IP Address is already taken"
        : "";
  const serialNumberError =
    typeof nextMakeModelSerialNumber === "string" &&
      (!prevEnrolled ||
        prevMakeModelSerialNumber !== nextMakeModelSerialNumber) &&
      enrolledMakeModelSerialNumbers.includes(nextMakeModelSerialNumber)
      ? "Make + Model + Serial Number combination is already taken"
      : "";
  const snmpUsernameError =
    nextSnmpV3Enabled && !nextSnmpV3Username.length
      ? "*SNMP Username is required"
      : "";
  const snmpV3AuthPassphraseError = !nextSnmpV3Enabled
    ? ""
    : !nextSnmpV3AuthPassphrase?.length
      ? "*AuthPassphrase is required"
      : nextSnmpV3AuthPassphrase.length < 8
        ? "AuthPassphrase must contain at least 8 characters"
        : "";
  const snmpV3PrivPassphraseError = !nextSnmpV3Enabled
    ? ""
    : !nextSnmpV3PrivPassphrase?.length
      ? "*PrivPassphrase is required"
      : nextSnmpV3PrivPassphrase.length < 8
        ? "PrivPassphrase must contain at least 8 characters"
        : "";
  const sshUsernameError =
    nextSshEnabled && !nextSshUsername.length
      ? "*SSH Username is required"
      : "";
  const sshPasswordError =
    nextSshEnabled && !nextSshPassword.length
      ? "*SSH Password is required"
      : "";

  const allMakes = [
    ...(new Set(makeModelSerialNumbers.map((mms) => mms.split(",")[1])) as any),
  ].sort();
  let filteredMakes = allMakes.filter(
    (make) =>
      makeModelSerialNumbers.find((mms) =>
        mms.startsWith(`${nextDeviceType.toUpperCase()},${make},`)
      ) !== undefined
  );

  if (nextModel) {
    filteredMakes = filteredMakes.filter(
      (make) =>
        makeModelSerialNumbers.find((mms) =>
          mms.startsWith(`${nextDeviceType.toUpperCase()},${make},${nextModel}`)
        ) !== undefined
    );
  }
  const allModels = [
    ...(new Set(makeModelSerialNumbers.map((mms) => mms.split(",")[2])) as any),
  ].sort();
  let filteredModels = allModels.filter((model) =>
    makeModelSerialNumbers.find(
      (mms) =>
        mms.startsWith(`${nextDeviceType.toUpperCase()},`) &&
        mms.includes(`,${model}`)
    )
  );

  if (nextMake) {
    filteredModels = filteredModels.filter(
      (model) =>
        makeModelSerialNumbers.find((mms) =>
          mms.startsWith(
            `${nextDeviceType.toUpperCase()},${nextMake.toUpperCase()},${model}`
          )
        ) !== undefined
    );
  }
  const isEnrollable =
    prevEnrolled ||
    (!ipAddresses.includes(nextIpAddress) &&
      (nextMakeModelSerialNumber === null ||
        !enrolledMakeModelSerialNumbers.includes(nextMakeModelSerialNumber)));


  const isSubmitDisabled = useMemo(() => {

    /**
     * If data set is not changed then return null
     * If data set has change then
     *   return true if needed information fulfilled
     *   return false if data modification needed
     */
    const isSnmpV3DataReadyToSubmit = (): boolean | null => {
      /**
       * If there is no update then return null
       */
      if (prevSnmpV3Enabled === nextSnmpV3Enabled &&
        prevSnmpV3Username === nextSnmpV3Username &&
        prevSnmpV3AuthenticationProtocol === nextSnmpV3AuthenticationProtocol &&
        prevSnmpV3AuthPassphrase === nextSnmpV3AuthPassphrase &&
        prevSnmpV3PrivacyProtocol === nextSnmpV3PrivacyProtocol &&
        prevSnmpV3PrivPassphrase === nextSnmpV3PrivPassphrase &&
        prevSnmpV3Port === nextSnmpV3Port &&
        prevSnmpV3SecurityLevel === nextSnmpV3SecurityLevel
      ) {
        return null;
      }
      /**
       * If any change exists then check if required configuration is fulfilled.
       */
      // If snmp will be disabled then skip check
      if (nextSnmpV3Enabled !== true || nextSnmpV3Enabled === undefined) {
        return true
      }
      // If security level is not configured then not ready
      if (nextSnmpV3SecurityLevel === null) {
        return false;
      }
      switch (nextSnmpV3SecurityLevel) {
        case "authPriv": {
          if (!!snmpV3AuthPassphraseError || !!snmpV3PrivPassphraseError) {
            return false;
          }
          if (nextSnmpV3AuthPassphrase && nextSnmpV3PrivPassphrase && nextSnmpV3Username && nextSnmpV3AuthenticationProtocol && nextSnmpV3Port && nextSnmpV3PrivacyProtocol) {
            return true;
          }
          break;
        }
        case "authNoPriv": {
          if (!!snmpV3AuthPassphraseError) {
            return false;
          }
          if (nextSnmpV3AuthPassphrase && nextSnmpV3Username && nextSnmpV3AuthenticationProtocol && nextSnmpV3Port) {
            return true;
          }
          break;
        }
        case "noAuthNoPriv": {
          if (nextSnmpV3Username && nextSnmpV3Port) {
            return true;
          }
          break;
        }
      }
      return false;
    }

    const snmpV3Ready = isSnmpV3DataReadyToSubmit();

    return (
      !isEnrollable ||
      !!ipAddressError ||
      !!serialNumberError ||
      !!snmpUsernameError ||
      !!sshUsernameError ||
      !!sshPasswordError ||
      snmpV3Ready === false ||
      (prevDeviceType === nextDeviceType &&
        prevIpAddress === nextIpAddress &&
        prevSerialNumber === nextSerialNumber &&
        prevMake === nextMake &&
        prevModel === nextModel &&
        prevEnrolled === nextEnrolled &&
        prevPingEnabled === nextPingEnabled &&
        prevSshEnabled === nextSshEnabled &&
        prevSshUsername === nextSshUsername &&
        prevSshPassword === nextSshPassword &&
        snmpV3Ready === null)
    );
  }, [
    isEnrollable,
    ipAddressError,
    serialNumberError,
    snmpUsernameError,
    sshUsernameError,
    sshPasswordError,
    prevDeviceType,
    nextDeviceType,
    prevIpAddress,
    nextIpAddress,
    prevSerialNumber,
    nextSerialNumber,
    prevMake,
    nextMake,
    prevModel,
    nextModel,
    prevEnrolled,
    nextEnrolled,
    prevPingEnabled,
    nextPingEnabled,
    prevSshEnabled,
    nextSshEnabled,
    prevSshUsername,
    nextSshUsername,
    prevSshPassword,
    nextSshPassword,
    nextSnmpV3AuthPassphrase,
    nextSnmpV3AuthenticationProtocol,
    nextSnmpV3Enabled,
    nextSnmpV3Port,
    nextSnmpV3PrivPassphrase,
    nextSnmpV3PrivacyProtocol,
    prevSnmpV3SecurityLevel,
    prevSnmpV3Username,
    snmpV3AuthPassphraseError,
    snmpV3PrivPassphraseError,
    nextSnmpV3SecurityLevel,
    nextSnmpV3Username,
    prevSnmpV3AuthPassphrase,
    prevSnmpV3AuthenticationProtocol,
    prevSnmpV3Enabled,
    prevSnmpV3Port,
    prevSnmpV3PrivPassphrase,
    prevSnmpV3PrivacyProtocol
  ]);

  return (
    <Modal
      className="light"
      header={<h2>{headerText}</h2>}
      footer={
        <Button
          className="action lg"
          disabled={isSubmitDisabled
          }
          onClick={() => {
            const nextDevice = {
              changedAt: new Date().getTime(),
              deviceClass: "FIXED" as const,
              deviceMetadata: {
                ...(prevDevice?.deviceMetadata
                  ? JSON.parse(prevDevice.deviceMetadata)
                  : {}),
                managed_by: null, // TODO: Put tenant value here?
                ping: nextPingEnabled,
              },
              deviceSecrets: prevDevice?.deviceSecrets
                ? JSON.parse(prevDevice.deviceSecrets)
                : {},
              deviceType: nextDeviceType,
              enrolled: nextEnrolled,
              id: prevDevice?.id ?? generateRandomString(),
              ipAddress: nextIpAddress,
              make: nextMake as string,
              model: nextModel as string,
              serialNumber: nextSerialNumber as string,
              siteId: site.id,
              snmpUsername: nextSnmpV3Username,
              sshUsername: nextSshUsername,
            };

            if (deviceSupportsSnmpV3(nextDevice)) {
              // TODO: Need to Update this
              // Need to have map for the level
              nextDevice.deviceMetadata.snmp = nextSnmpV3Enabled;
              switch (nextSnmpV3SecurityLevel) {
                case "authPriv": {
                  nextDevice.deviceSecrets = {
                    ...nextDevice.deviceSecrets,
                    snmp_level: nextSnmpV3SecurityLevel,
                    snmp_port: nextSnmpV3Port,
                    snmp_username: nextSnmpV3Username,
                    snmp_auth_protocol: nextSnmpV3AuthenticationProtocol,
                    snmp_auth_password: nextSnmpV3AuthPassphrase,
                    snmp_priv_protocol: nextSnmpV3PrivacyProtocol,
                    snmp_priv_password: nextSnmpV3PrivPassphrase
                  }
                  break;
                }
                case "authNoPriv": {
                  nextDevice.deviceSecrets = {
                    ...nextDevice.deviceSecrets,
                    snmp_level: nextSnmpV3SecurityLevel,
                    snmp_port: nextSnmpV3Port,
                    snmp_username: nextSnmpV3Username,
                    snmp_auth_protocol: nextSnmpV3AuthenticationProtocol,
                    snmp_auth_password: nextSnmpV3AuthPassphrase,
                    snmp_priv_protocol: undefined,
                    snmp_priv_password: undefined
                  }
                  break;
                }
                case "noAuthNoPriv": {
                  nextDevice.deviceSecrets = {
                    ...nextDevice.deviceSecrets,
                    snmp_level: nextSnmpV3SecurityLevel,
                    snmp_port: nextSnmpV3Port,
                    snmp_username: nextSnmpV3Username,
                    snmp_auth_protocol: undefined,
                    snmp_auth_password: undefined,
                    snmp_priv_protocol: undefined,
                    snmp_priv_password: undefined
                  }
                  break;
                }
              }

            }

            if (deviceSupportsSsh(nextDevice)) {
              nextDevice.deviceMetadata.ssh = nextSshEnabled;
              nextDevice.deviceSecrets.ssh_password = nextSshPassword;
              nextDevice.deviceSecrets.ssh_username = nextSshUsername;
            }
            confirmAction(nextDevice);
          }}
          style={{ marginTop: 32 }}
        >
          CONFIRM
        </Button>
      }
    >
      <div
        style={{
          height: 64,
          marginTop: 6,
          marginBottom: 24,
          overflow: "visible",
        }}
      >
        <Radio
          changed={
            !headerText.includes("New") && prevDeviceType !== nextDeviceType
          }
          label="Device Type"
          name="device-type"
          onChange={(type) => setNextDeviceType(type as any)}
          options={[
            {
              label: getDeviceTypeLabel("SWITCH"),
              value: "SWITCH",
            },
            {
              label: getDeviceTypeLabel("RSU"),
              value: "RSU",
            },
            {
              label: getDeviceTypeLabel("CVP"),
              value: "CVP",
            },
            {
              label: getDeviceTypeLabel("TSC"),
              value: "TSC",
            },
            {
              label: getDeviceTypeLabel("LIDAR"),
              value: "LIDAR",
            },
          ]}
          required
          value={nextDeviceType}
        />
      </div>
      <div style={{ width: "100%", height: 80, marginBottom: 24 }}>
        <Input
          autoFocus
          changed={
            !headerText.includes("New") && prevIpAddress !== nextIpAddress
          }
          id="ip-address"
          label="IP Address"
          onChange={(e) => {
            const parts = e.target.value
              .split(".")
              .map((part, idx) =>
                part === "" || Number.isNaN(parseInt(part, 10))
                  ? ""
                  : parseInt(part, 10).toString().length === 4 && idx < 3
                    ? `${Math.min(
                      255,
                      Math.max(
                        0,
                        parseInt(parseInt(part, 10).toString().slice(0, 3), 10)
                      )
                    )}.${Math.min(
                      255,
                      Math.max(
                        0,
                        parseInt(parseInt(part, 10).toString()[3], 10)
                      )
                    )}`
                    : Math.min(255, Math.max(0, parseInt(part, 10)))
              );

            setNextIpAddress(parts.slice(0, 4).join("."));
          }}
          placeholder="e.g. 1.1.1.1"
          required
          type="text"
          validate={() => ipAddressError}
          value={nextIpAddress}
        />
      </div>
      <div style={{ width: "100%", height: 80, marginBottom: 24 }}>
        <Input
          changed={
            !headerText.includes("New") && prevSerialNumber !== nextSerialNumber
          }
          id="serial-number"
          label="Serial Number"
          maxLength={200}
          onChange={(e) => setNextSerialNumber(e.target.value.replace(",", ""))}
          placeholder="e.g. ABC000001"
          type="text"
          validate={() => serialNumberError}
          value={nextSerialNumber}
        />
      </div>
      <div style={{ width: "100%", height: 80, marginBottom: 24 }}>
        <Input
          changed={!headerText.includes("New") && prevMake !== nextMake}
          id="make"
          label="Make"
          maxLength={200}
          onChange={(e) =>
            setNextMake(e.target.value.toUpperCase().replace(",", ""))
          }
          placeholder="e.g. ACME"
          recommendations={filteredMakes}
          type="text"
          value={nextMake}
          warning={
            !!nextMake && !allMakes.includes(nextMake.toUpperCase())
              ? "New Make"
              : ""
          }
        />
      </div>
      <div style={{ width: "100%", height: 80, marginBottom: 24 }}>
        <Input
          changed={!headerText.includes("New") && prevModel !== nextModel}
          id="model"
          label="Model"
          maxLength={200}
          onChange={(e) =>
            setNextModel(e.target.value.toUpperCase().replace(",", ""))
          }
          placeholder="e.g. ABC-1234"
          recommendations={filteredModels}
          type="text"
          value={nextModel}
          warning={
            allModels.includes(nextModel?.toUpperCase()) &&
              !filteredModels.includes(nextModel?.toUpperCase())
              ? "Model might belong to another Make"
              : !!nextModel && !allModels.includes(nextModel.toUpperCase())
                ? "New Model"
                : ""
          }
        />
      </div>
      <div>
        <label>Network Access</label>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 24,
            marginBottom: 24,
          }}
        >
          <Toggle
            changed={
              !headerText.includes("New") && prevEnrolled !== nextEnrolled
            }
            checked={nextEnrolled}
            id="enrolled"
            onChange={() => setNextEnrolled(!nextEnrolled)}
          />
          <div style={{ marginLeft: 18 }}>
            <label htmlFor="enrolled" style={{ cursor: "pointer" }}>
              {nextEnrolled ? "" : "Not "}Enrolled
            </label>
            <p style={{ marginTop: 4 }}>
              Enrolled Devices are Devices that Cirrus should track and
              communicate with. Enrolled Devices incur service charges according
              to usage and plan details. Unenrolled Device configurations will
              be kept for easy reenrollment.
            </p>
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center", marginTop: 24 }}>
          <Toggle
            changed={
              !headerText.includes("New") && prevPingEnabled !== nextPingEnabled
            }
            checked={nextPingEnabled}
            id="ping-enabled"
            onChange={() => setNextPingEnabled(!nextPingEnabled)}
          />
          <div style={{ marginLeft: 18 }}>
            <label htmlFor="ping-enabled" style={{ cursor: "pointer" }}>
              Ping{" "}
              {nextPingEnabled
                ? `Enabled${nextEnrolled ? "" : " (Not Enrolled)"}`
                : "Disabled"}
            </label>
            <p style={{ marginTop: 4 }}>
              The Cirrus ping service automates routine pings to the IP
              addresses of configured and enabled Devices in order to check
              connectivity. Successful pings confirm reachability on the network
              and failed pings can generate health alerts in downstream
              applications.
            </p>
          </div>
        </div>
        {["RSU"].includes(nextDeviceType) ? (
          <>
            <div
              style={{ display: "flex", alignItems: "center", marginTop: 24 }}
            >
              <Toggle
                changed={
                  !headerText.includes("New") &&
                  prevSnmpV3Enabled !== nextSnmpV3Enabled
                }
                checked={nextSnmpV3Enabled}
                id="snmp-enabled"
                onChange={() => setNextSnmpV3Enabled(!nextSnmpV3Enabled)}
              />
              <div style={{ marginLeft: 18 }}>
                <label htmlFor="snmp-enabled" style={{ cursor: "pointer" }}>
                  SNMP{" "}
                  {nextSnmpV3Enabled
                    ? `Enabled${nextEnrolled ? "" : " (Not Enrolled)"}`
                    : "Disabled"}
                </label>
                <p style={{ marginTop: 4 }}>
                  Simple Network Management Protocol (SNMP) is a standard
                  networking protocol used to manage and monitor configured and
                  enabled Devices. Depending on Device type and standards
                  conformance, SNMP can be used to collect detailed health and
                  status data or to issue commands to Devices.
                </p>
              </div>
            </div>
            {nextSnmpV3Enabled ||
              nextSnmpV3Username.length
              ? (
                <div>
                  <div
                    style={{
                      height: 64,
                      marginTop: 10,
                      marginBottom: 10,
                      overflow: "visible",
                      marginLeft: 46
                    }}
                  >
                    <Radio
                      changed={
                        !headerText.includes("New") && prevSnmpV3SecurityLevel !== nextSnmpV3SecurityLevel
                      }
                      label="SNMPv3 Security Level"
                      name="snmvv3-security-level"
                      onChange={(level) => handleSNMPv3SecurityLevelChange(level as any)}
                      options={SNMPv3_SECURITY_LEVEL.map(e => {
                        return {
                          label: e,
                          value: e
                        }
                      })}
                      required
                      value={nextSnmpV3SecurityLevel}
                    />
                  </div>
                  {nextSnmpV3SecurityLevel ?
                    <div>
                      <div
                        style={{
                          height: 80,
                          marginTop: 20,
                          marginLeft: 46,
                        }}
                      >
                        <Input
                          changed={
                            !headerText.includes("New") &&
                            prevSnmpV3Username !== nextSnmpV3Username
                          }
                          disabled={!nextSnmpV3Enabled}
                          id="snmp-username"
                          label="SNMP Username"
                          maxLength={200}
                          onChange={(e) => setNextSnmpV3Username(e.target.value)}
                          placeholder="e.g. test"
                          required
                          type="text"
                          value={nextSnmpV3Username}
                        />
                      </div>
                      <div
                        style={{
                          height: 60,
                          marginTop: 10,
                          marginLeft: 46,
                        }}
                      >
                        <Input
                          changed={
                            !headerText.includes("New") &&
                            prevSnmpV3Port !== nextSnmpV3Port
                          }
                          disabled={!nextSnmpV3Enabled}
                          id="snmp-port"
                          label="SNMP Port"
                          maxLength={200}
                          onChange={(e) => setNextSnmpV3Port(Number(e.target.value))}
                          placeholder="e.g. 161"
                          required
                          type="number"
                          value={nextSnmpV3Port}
                        />
                      </div>
                    </div>
                    : null}
                  {nextSnmpV3SecurityLevel && ["authPriv", "authNoPriv"].includes(nextSnmpV3SecurityLevel) ? (
                    <div>
                      <div
                        style={{
                          height: 64,
                          marginTop: 24,
                          marginBottom: 24,
                          overflow: "visible",
                          marginLeft: 46
                        }}
                      >
                        <Radio
                          changed={
                            !headerText.includes("New") && prevSnmpV3AuthenticationProtocol !== nextSnmpV3AuthenticationProtocol
                          }
                          label="Authentication Protocol"
                          name="snmvv3-authentication-protocol"
                          onChange={(protocol) => handleSNMPv3AuthenticationProtocolChange(protocol as any)}
                          options={SNMPv3_AUTHENTICATION_PROTOCOL.map(e => {
                            return {
                              label: e,
                              value: e
                            }
                          })}
                          required
                          value={nextSnmpV3AuthenticationProtocol}
                        />
                      </div>
                      <div
                        style={{
                          height: 60,
                          marginTop: 10,
                          marginLeft: 46,
                        }}
                      >
                        <Input
                          changed={
                            !headerText.includes("New") &&
                            prevSnmpV3AuthPassphrase !== nextSnmpV3AuthPassphrase
                          }
                          disabled={!nextSnmpV3Enabled}
                          id="authentication passphrase"
                          label="Authentication Passphrase"
                          maxLength={200}
                          onChange={(e) => setNextSnmpV3AuthPassphrase(e.target.value)}
                          required
                          type="password"
                          validate={() => snmpV3AuthPassphraseError}
                          value={nextSnmpV3AuthPassphrase}
                        />
                      </div>
                    </div>
                  ) : null}
                  {nextSnmpV3SecurityLevel && ["authPriv"].includes(nextSnmpV3SecurityLevel) ? (
                    <div>
                      <div
                        style={{
                          height: 64,
                          marginTop: 24,
                          marginBottom: 24,
                          overflow: "visible",
                          marginLeft: 46
                        }}
                      >
                        <Radio
                          changed={
                            !headerText.includes("New") && prevSnmpV3PrivacyProtocol !== nextSnmpV3PrivacyProtocol
                          }
                          label="Privacy Protocol"
                          name="snmvv3-privacy-protocol"
                          onChange={(protocol) => handleSNMPv3PrivacyProtocolChange(protocol as any)}
                          options={SNMPv3_PRIVACY_PROTOCOL.map(e => {
                            return {
                              label: e,
                              value: e
                            }
                          })}
                          required
                          value={nextSnmpV3PrivacyProtocol}
                        />
                      </div>
                      <div
                        style={{
                          height: 60,
                          marginTop: 10,
                          marginLeft: 46,
                        }}
                      >
                        <Input
                          changed={
                            !headerText.includes("New") &&
                            prevSnmpV3PrivPassphrase !== nextSnmpV3PrivPassphrase
                          }
                          disabled={!nextSnmpV3Enabled}
                          id="privacy passphrase"
                          label="Privacy Passphrase"
                          maxLength={200}
                          onChange={(e) => setNextSnmpV3PrivPassphrase(e.target.value)}
                          required
                          type="password"
                          validate={() => snmpV3PrivPassphraseError}
                          value={nextSnmpV3PrivPassphrase ?? undefined}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : null}
          </>
        ) : null}
        {["RSU"].includes(nextDeviceType) ? (
          <>
            <div
              style={{ display: "flex", alignItems: "center", marginTop: 24 }}
            >
              <Toggle
                changed={
                  !headerText.includes("New") &&
                  prevSshEnabled !== nextSshEnabled
                }
                checked={nextSshEnabled}
                id="ssh-enabled"
                onChange={() => setNextSshEnabled(!nextSshEnabled)}
              />
              <div style={{ marginLeft: 18 }}>
                <label htmlFor="ssh-enabled" style={{ cursor: "pointer" }}>
                  SSH{" "}
                  {nextSshEnabled
                    ? `Enabled${nextEnrolled ? "" : " (Not Enrolled)"}`
                    : "Disabled"}
                </label>
                <p style={{ marginTop: 4 }}>
                  Secure Shell (SSH) protocol is a method for sending custom
                  commands to configured and enabled Devices. Depending on
                  Device type, some commands can be used to fetch health and
                  status data or to issue commands to Devices.
                </p>
              </div>
            </div>
            {nextSshEnabled ||
              nextSshUsername.length ||
              nextSshPassword.length ? (
              <div
                style={{
                  height: 80,
                  marginTop: 24,
                  marginLeft: 46,
                }}
              >
                <Input
                  changed={
                    !headerText.includes("New") &&
                    prevSshUsername !== nextSshUsername
                  }
                  disabled={!nextSshEnabled}
                  id="ssh-username"
                  label="SSH Username"
                  maxLength={200}
                  onChange={(e) => setNextSshUsername(e.target.value)}
                  placeholder="e.g. test"
                  required
                  type="text"
                  value={nextSshUsername}
                />
              </div>
            ) : null}
            {nextSshEnabled ||
              nextSshUsername.length ||
              nextSshPassword.length ? (
              <div
                style={{
                  height: 80,
                  marginTop: 24,
                  marginLeft: 46,
                }}
              >
                <Input
                  changed={
                    !headerText.includes("New") &&
                    prevSshPassword !== nextSshPassword
                  }
                  disabled={!nextSshEnabled}
                  id="ssh-password"
                  label="SSH Password"
                  maxLength={200}
                  onChange={(e) => setNextSshPassword(e.target.value)}
                  type="password"
                  required
                  value={nextSshPassword}
                  warning={
                    validatePasswordComplexity(nextSshPassword)
                      ? ""
                      : "At least 1 uppercase letter, 1 lowercase letter, 1 number, and 8 total characters are recommended"
                  }
                />
              </div>
            ) : null}
          </>
        ) : null}
      </div>
    </Modal>
  );
};

export default DeviceModal;