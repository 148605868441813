import React from "react";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Button, Chip } from "@mui/material";
import { GridActionsCellItem, GridToolbarQuickFilter } from "@mui/x-data-grid";

import Loader from "../Loader";
import InteractiveTable from "../controls/InteractiveTable";
import NotFoundPage from "../pages/NotFoundPage";
import { canAccessService, getRoleName } from "../../lib/auth";
import { useAuth } from "../../state/authState";
import { useUI } from "../../state/uiState";

import { User } from "../../lib/auth";
import { epochToDate, epochToTime } from "../../lib/formatting";

const UsersListToolbar = ({ rowCount }: { rowCount: number }) => {
  const { setOverlay } = useUI();

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <h2
            style={{
              marginBottom: 8,
              fontSize: "24px",
              lineHeight: "32px",
            }}
          >
            User Manager
          </h2>
          <p style={{ fontSize: "16px", lineHeight: "24px" }}>
            Manage your team members and their Cirrus access here.
          </p>
        </div>
        <div style={{ marginBottom: 52 }}>
          <GridToolbarQuickFilter
            debounceMs={250}
            placeholder="Search Users"
            size="small"
            variant="outlined"
          />
          <Button
            color="primary"
            onClick={() => setOverlay("addUser")}
            size="large"
            startIcon={<AddIcon />}
            style={{
              height: 40,
              marginLeft: 12,
              border: "solid 1px transparent",
            }}
            variant="contained"
          >
            Add User
          </Button>
        </div>
      </div>
      <h2 style={{ marginBottom: 12, fontSize: "20px", lineHeight: "32px" }}>
        All Users ({rowCount})
      </h2>
    </div>
  );
};

const UsersListPage = ({
  loading,
  users,
}: {
  loading: boolean;
  users: User[];
}) => {
  const { authStatus, currentUser } = useAuth();
  const navigate = useNavigate();
  const { setOverlay } = useUI();

  if (authStatus !== "ready" || !users.length) {
    return <Loader />;
  }

  if (
    !canAccessService(currentUser.authMetadata, "web_client:user_management")
  ) {
    return <NotFoundPage />;
  }
  return (
    <div
      style={{
        width: "calc(100vw - 80px)",
        height: `calc(100vh - 64px)`,
        padding: "76px 0 40px 0",
      }}
    >
      <InteractiveTable
        toolbar={UsersListToolbar}
        columns={[
          {
            field: "name",
            flex: 2,
            headerName: "Name",
            resizable: false,
          },
          {
            field: "emailAddress",
            flex: 3,
            headerName: "Email Address",
            resizable: false,
          },
          {
            field: "role",
            flex: 2,
            headerName: "Role",
            renderCell: (params) => (
              <Chip
                color={params.value === "Administrator" ? "info" : "default"}
                label={params.value}
                size="small"
              />
            ),
          },
          {
            field: "createdAt",
            flex: 2,
            headerName: "Created At",
            valueGetter: (value) =>
              `${epochToDate(value)} ${epochToTime(value)}`,
          },
          {
            field: "lastLoginAt",
            flex: 2,
            headerName: "Last Login",
            valueGetter: (value) =>
              value ? `${epochToDate(value)} ${epochToTime(value)}` : "Never",
          },
          {
            field: "actions",
            type: "actions",
            width: 50,
            getActions: (params) => [
              <GridActionsCellItem
                key="viewUser"
                icon={<VisibilityIcon />}
                label="View User"
                onClick={() =>
                  navigate(`/users?u=${encodeURIComponent(params.id)}`)
                }
                showInMenu
                style={{ marginTop: -8, borderBottom: "solid 1px #ffffff" }}
              />,
              <GridActionsCellItem
                key="editUser"
                disabled={params.row.role === "Administrator"}
                icon={<EditIcon />}
                label="Edit User"
                onClick={() => setOverlay(`editUser:${params.id}`)}
                showInMenu
                style={{ borderBottom: "solid 1px #ffffff" }}
              />,
              <GridActionsCellItem
                key="removeUser"
                disabled={params.row.role === "Administrator"}
                icon={<DeleteIcon />}
                label="Remove User"
                onClick={() => setOverlay(`removeUser:${params.id}`)}
                showInMenu
                style={{ marginBottom: -8 }}
              />,
            ],
          },
        ]}
        rows={users.map((user) => ({
          id: user.id,
          name: `${user.firstName} ${user.lastName}`,
          emailAddress: user.emailAddress,
          role: getRoleName(user.authMetadata),
          createdAt: user.createdAt,
          lastLoginAt: user.lastLoginAt,
        }))}
      />
    </div>
  );
};
export default UsersListPage;
