import dayjs from "dayjs";
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { DateTimePicker } from "@mui/x-date-pickers";

import { API_ABORT_MESSAGE, callRestApi, fetchAssets } from "../../lib/api";
import { fromStringToDayjs, fromStringToEpoch } from "../../lib/formatting";
import { useSearchParam } from "../../lib/hooks";
import {
  MapMessageParser,
  getCiptStateValue,
  getVehicleRole,
  parseSPaTStatusString,
  tmdToDeg,
} from "../../lib/v2x/v2xUtils";
import { useAuth } from "../../state/authState";
import { useUI } from "../../state/uiState";

import NotFoundPage from "./NotFoundPage";
import Loader from "../Loader";
import AssetsPanel from "../timeTravel/AssetsPanel";
import MapWrapper from "../timeTravel/MapWrapper";
import MessagesPanel from "../timeTravel/MessagesPanel";

import config from "../../config";

import { UserAuthMetadata } from "../../lib/auth";
import { FixedDevice, NormalizedSite } from "../../lib/devices/deviceTypes";
import { Position } from "../../lib/json";
import {
  CIPTStatus,
  MAPLaneCenterlineFeature,
  MAPLaneSurfaceFeature,
  MAPReferencePointFeature,
  SPaTMovementCenterlineFeature,
  SPaTMovementSurfaceFeature,
  VehicleTelemetryPointFeature,
} from "../../lib/v2x/v2xTypes";
import { LaneList } from "../../../../submodules/j2735-ts/src/1603/MapData";
import {
  MovementPhaseState,
  MovementState,
} from "../../../../submodules/j2735-ts/src/1603/SPAT";

type DataAction =
  | {
      type: "LOAD_REPLAY_START";
    }
  | {
      type: "SET_ASSETS";
      payload: {
        devices: { [deviceId: string]: FixedDevice };
        devicesLookup: { [deviceKey: string]: string };
        sites: { [siteId: string]: NormalizedSite };
        sitesLookup: { [siteKey: string]: string };
        snapshotIntervalMax: number;
      };
    }
  | {
      type: "SET_REPLAY";
      payload: ReplayResponse;
    };

type DataState = {
  devices: { [deviceId: string]: FixedDevice };
  devicesLookup: { [deviceKey: string]: string };
  sites: { [siteId: string]: NormalizedSite };
  sitesLookup: { [siteKey: string]: string };
  loadingAssets: boolean;
  loadingReplay: boolean;
  messages: {
    laneCenterlines: MAPLaneCenterlineFeature[];
    laneSurfaces: MAPLaneSurfaceFeature[];
    movementCenterlines: SPaTMovementCenterlineFeature[];
    movementSurfaces: SPaTMovementSurfaceFeature[];
    referencePoints: MAPReferencePointFeature[];
    vehicleTelemetry: VehicleTelemetryPointFeature[];
  };
  signalTypeStates: {
    cipt: CIPTStatus;
    from: number;
    to: number;
    signalGroup: number;
    eventState: MovementPhaseState;
  }[];
  snapshotIntervalMax: number;
};

type ReplayResponse = {
  events: [];
  messages: {
    maps: {
      intersectionId: string;
      refLat: number;
      refLon: number;
      laneWidth: number;
      laneSet: LaneList;
      from: number;
      to: number;
    }[];
    spats: {
      intersectionId: string;
      status: string;
      from: number;
      to: number;
      states: MovementState[];
    }[];
    vehicles: {
      tempId: string;
      timestampUtc: string;
      latitude: number;
      longitude: number;
      heading: number;
      speed: number;
      transmissionState: string;
      srmRequests: {
        request: {
          id: { id: number };
          inBoundLane: { lane: number };
          requestID: number;
          requestType: string;
        };
        vehicle_role: string;
      }[];
    }[];
  };
};

const initialData: DataState = {
  devices: {},
  devicesLookup: {},
  sites: {},
  sitesLookup: {},
  loadingAssets: true,
  loadingReplay: false,
  messages: {
    laneCenterlines: [],
    laneSurfaces: [],
    movementCenterlines: [],
    movementSurfaces: [],
    referencePoints: [],
    vehicleTelemetry: [],
  },
  signalTypeStates: [],
  snapshotIntervalMax: NaN,
};

const dataReducer = (state: DataState, action: DataAction): DataState => {
  switch (action.type) {
    case "LOAD_REPLAY_START": {
      return {
        ...state,
        loadingReplay: true,
      };
    }
    case "SET_ASSETS": {
      return {
        ...state,
        ...action.payload,
        loadingAssets: false,
      };
    }
    case "SET_REPLAY": {
      const parsers = action.payload.messages.maps.map(
        (mapMessage) => new MapMessageParser(mapMessage)
      );

      return {
        ...state,
        loadingReplay: false,
        messages: {
          laneCenterlines: parsers
            .map((parser) => parser.laneCenterlines)
            .flat(),
          laneSurfaces: parsers.map((parser) => parser.laneSurfaces).flat(),
          movementCenterlines: parsers
            .map((parser) => parser.movementCenterlines)
            .flat(),
          movementSurfaces: parsers
            .map((parser) => parser.movementSurfaces)
            .flat(),
          referencePoints: parsers
            .map((parser) => parser.referencePoints)
            .flat(),
          vehicleTelemetry: [...action.payload.messages.vehicles].map(
            (vehicleTelemetry) => {
              const [date, time] = vehicleTelemetry.timestampUtc.split(" ");

              return {
                type: "Feature" as const,
                geometry: {
                  type: "Point" as const,
                  coordinates: [
                    tmdToDeg(vehicleTelemetry.longitude),
                    tmdToDeg(vehicleTelemetry.latitude),
                    0,
                  ] as Position,
                },
                properties: {
                  time: parseFloat(
                    (dayjs(`${date}T${time}Z`).valueOf() * 0.001).toFixed(1)
                  ),
                  heading: parseFloat(
                    (vehicleTelemetry.heading * 0.0125).toFixed(1)
                  ),
                  messageId: [
                    vehicleTelemetry.tempId,
                    vehicleTelemetry.timestampUtc,
                  ].join("_"),
                  messageType: vehicleTelemetry.srmRequests?.length
                    ? "SRM"
                    : "BSM",
                  vehicleId: vehicleTelemetry.tempId,
                  vehicleRole: vehicleTelemetry.srmRequests?.length
                    ? getVehicleRole(
                        vehicleTelemetry.srmRequests[0].vehicle_role
                      )
                    : null,
                  laneId: vehicleTelemetry.srmRequests?.length
                    ? vehicleTelemetry.srmRequests[0].request.inBoundLane.lane
                    : null,
                  speed: parseFloat((vehicleTelemetry.speed * 0.02).toFixed(1)),
                  ciptState: vehicleTelemetry.srmRequests?.length
                    ? getCiptStateValue(
                        vehicleTelemetry.srmRequests[0].request.requestType
                      )
                    : null,
                },
              };
            }
          ),
        },

        signalTypeStates: action.payload.messages.spats
          .map(
            (spat) =>
              spat.states.map((spatState) =>
                spatState["state-time-speed"].map((stateTimeSpeed) => ({
                  cipt: parseSPaTStatusString(spat.status),
                  from: spat.from,
                  to: spat.to,
                  signalGroup: spatState.signalGroup,
                  eventState: stateTimeSpeed.eventState,
                }))
              ) as any
          )
          .flat(2),
      };
    }
  }
};

const getInitialData = (source: string, from: string) => ({
  ...initialData,
  loadingReplay: !!source && !!from,
});

const TimeTravelPage = ({
  authorize,
}: {
  authorize: (uam: UserAuthMetadata) => boolean;
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const { currentTenant, currentUser, getDebug } = useAuth();
  const [source] = useSearchParam("source");
  const [from, setFrom] = useSearchParam("from");
  const [scrubRaw] = useSearchParam("scrub");
  const { addAlerts, hoveredObject } = useUI();

  const scrub = parseFloat(scrubRaw ? scrubRaw : "0");
  const debug = getDebug();

  const [dataState, dispatchDataAction] = React.useReducer(
    dataReducer,
    getInitialData(source, from)
  );

  const currentDevice = dataState.devices[dataState.devicesLookup[source]];
  const currentSite = currentDevice?.siteId
    ? dataState.sites[currentDevice.siteId]
    : dataState.sites[dataState.sitesLookup[source]] ?? null;
  const currentDevices = currentSite
    ? currentSite.deviceIds.map((deviceId) => dataState.devices[deviceId])
    : [];
  const currentRsu = currentDevices.find(
    (device) => device.deviceType === "RSU"
  );
  const currentSource =
    currentSite && !currentSite.id.includes(",")
      ? currentSite.id
      : currentRsu?.ipAddress ?? "";

  React.useEffect(() => {
    const abortController = new AbortController();

    const loadTimeTravelData = async () => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: config.services.restApi.basePath,
        },
      });

      try {
        const assets = await fetchAssets({
          accessToken,
          currentTenant,
          signal: abortController.signal,
        });
        dispatchDataAction({ type: "SET_ASSETS", payload: assets });
      } catch (error) {
        if (error === API_ABORT_MESSAGE) {
          console.warn(error);
        } else {
          console.error(error);
        }
      }

      if (!!currentSource && !!from) {
        try {
          dispatchDataAction({ type: "LOAD_REPLAY_START" });

          const path = `/replay?tenant=${currentTenant}&from=${fromStringToEpoch(
            from
          )}&source=${currentSource}`;

          const replayResponse = await callRestApi<ReplayResponse>({
            accessToken,
            path,
            signal: abortController.signal,
          });

          if (
            !replayResponse.messages.maps.length &&
            !replayResponse.messages.vehicles.length
          ) {
            addAlerts([
              {
                severity: "info",
                message:
                  "No messages found from this source over the requested interval",
              },
            ]);
          }
          dispatchDataAction({ type: "SET_REPLAY", payload: replayResponse });
        } catch (error) {
          if (error === API_ABORT_MESSAGE) {
            console.warn(error);
          } else {
            console.error(error);
            if (debug === "enabled") {
              addAlerts([
                {
                  severity: "error",
                  message: (error as any).toString(),
                },
              ]);
            }
          }
        }
      }
    };

    loadTimeTravelData();

    return () => {
      abortController.abort(API_ABORT_MESSAGE);
    };
    // addAlerts can't be included here without causing a reload loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently, currentTenant, currentSource, from]);

  const hasMessages =
    currentSource &&
    (dataState.messages.laneCenterlines.length ||
      dataState.messages.vehicleTelemetry.length);
  const max = dayjs(dataState.snapshotIntervalMax * 1000);
  const nowDayJs = from ? fromStringToDayjs(from) : max.subtract(5, "minutes");
  const now = nowDayJs.valueOf() * 0.001 + scrub;

  const [messageIds, setMessageIds] = React.useState(new Array<string>());

  const addMessageId = (messageId: string) => {
    setMessageIds((prevMessageIds) => {
      const messageIdIndex = prevMessageIds.findIndex(
        (msgId) => msgId === messageId
      );
      const nextMessageIds =
        messageIdIndex === -1
          ? [...prevMessageIds, messageId]
          : [
              ...prevMessageIds.slice(0, messageIdIndex),
              ...prevMessageIds.slice(messageIdIndex + 1),
            ];

      return nextMessageIds;
    });
  };

  let minDateTime = dayjs().subtract(5, "years").subtract(1, "second");
  const coordsList = Object.values(dataState.sites);

  let focusCoordinates =
    // Testing for > 1 instead of > 0 solves the problem of the map view state not updating on focus for single-Site tenants
    (
      coordsList.length > 1
        ? coordsList.map((site) => [site.lon, site.lat])
        : config.defaultBbox
    )
      .map((coords) => coords.join(","))
      .join("|");

  if (currentSource) {
    if (currentSite) {
      focusCoordinates = `${currentSite.lon},${currentSite.lat}`;
    }
  }
  if (minDateTime.get("seconds") !== 0) {
    minDateTime = minDateTime.add(1, "minute");
  }
  minDateTime = minDateTime.set("seconds", 0).subtract(1, "second");

  if (dataState.loadingAssets) {
    return <Loader />;
  }
  const movementSurfaces = dataState.signalTypeStates
    .map((signalTypeState) => {
      const matchingSignalSurfaces = dataState.messages.movementSurfaces.filter(
        (movementSurface) =>
          (movementSurface.properties as any).signalGroup ===
          signalTypeState.signalGroup
      );

      return matchingSignalSurfaces.map((matchingSignalSurface) => ({
        ...matchingSignalSurface,
        properties: {
          ...matchingSignalSurface.properties,
          cipt: signalTypeState.cipt,
          eventState: signalTypeState.eventState,
          from: signalTypeState.from,
          to: signalTypeState.to,
          messageId: [
            (matchingSignalSurface.properties as any).messageId,
            signalTypeState.from,
          ].join("_"),
        },
      }));
    })
    .flat();

  return authorize(currentUser.authMetadata) ? (
    <>
      <div id="tutorial-bar">
        <div
          className={`tutorial-step${
            currentSource ? " finished" : " in-progress"
          }`}
          style={{ flex: 3 }}
        >
          <div className="tutorial-step-badge">1</div>
          <div className="tutorial-step-text">
            <h2>Select a source</h2>
            <p>
              Select a source asset from the map or the list to see its Device
              information.
            </p>
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <div
            className={`horizontal-line${
              currentSource && from ? " finished" : ""
            }`}
          />
        </div>
        <div
          className={`tutorial-step${
            currentSource && from
              ? " finished"
              : currentSource
              ? " in-progress"
              : ""
          }`}
          style={{ flex: 3 }}
        >
          <div className="tutorial-step-badge">2</div>
          <div className="tutorial-step-text">
            <h2>Select a start time</h2>
            <p>
              Select a start time to replay historical data from the source
              asset.
            </p>
          </div>
        </div>
        <div className="tutorial-step" style={{ flex: 3 }}>
          <DateTimePicker
            autoFocus
            ampm={!localStorage.getItem("24h")}
            disabled={!currentSource}
            minDateTime={minDateTime}
            maxDateTime={dayjs(dataState.snapshotIntervalMax * 1000)}
            showDaysOutsideCurrentMonth
            timeSteps={{
              minutes: 1,
            }}
            onAccept={(value) => {
              if (!value) return;

              setFrom(
                value.format(["YYYY", "MM", "DD", "HH", "mm", "ZZ"].join("_"))
              );
            }}
            className="cirrus-input"
            sx={{
              width: "100%",
              ".MuiInputBase-root": {
                height: 40,
                backgroundColor: "#393939",
                color: "white",
                border: "none",
              },
              "& .MuiInputBase-input": {
                height: 40,
                boxSizing: "border-box",
                borderRadius: "6px",
              },
              ".MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              ".MuiButtonBase-root": {
                width: 40,
                height: 40,
                color: "white",
              },
            }}
            value={nowDayJs}
          />
        </div>
      </div>
      <div id="panels">
        <AssetsPanel
          currentSite={currentSite}
          devices={dataState.devices}
          focusCoordinates={focusCoordinates}
          sites={Object.values(dataState.sites)}
        />
        {hasMessages && messageIds.length ? (
          <MessagesPanel
            messageIds={messageIds}
            setMessageIds={setMessageIds}
            laneSurfaces={dataState.messages.laneSurfaces}
            movementSurfaces={movementSurfaces}
            vehicleTelemetry={dataState.messages.vehicleTelemetry.map(
              (vehicleTelemetryMessage) => ({
                ...vehicleTelemetryMessage,
                properties: {
                  ...vehicleTelemetryMessage.properties,
                  age: parseFloat(
                    (now - vehicleTelemetryMessage.properties.time).toFixed(1)
                  ), // TODO: Reimplement this as a Mapbox filter
                },
              })
            )}
          />
        ) : null}
        <MapWrapper
          focusCoordinates={focusCoordinates}
          addMessageId={addMessageId}
          setMessageIds={setMessageIds}
          messageIds={messageIds}
          loading={dataState.loadingReplay}
          laneCenterlines={{
            type: "FeatureCollection",
            features: dataState.messages.laneCenterlines,
          }}
          laneSurfaces={{
            type: "FeatureCollection",
            features: dataState.messages.laneSurfaces.map((laneSurface) => ({
              ...laneSurface,
              properties: {
                ...laneSurface.properties,
                highlighted:
                  hoveredObject === (laneSurface.properties as any).messageId,
              },
            })),
          }}
          mapReferencePoints={{
            type: "FeatureCollection",
            features: dataState.messages.referencePoints,
          }}
          movementCenterlines={{
            type: "FeatureCollection",
            features: dataState.messages.movementCenterlines
              .map((movementCenterline) => {
                const csts = dataState.signalTypeStates.find(
                  (sts) =>
                    sts.signalGroup ===
                      (movementCenterline.properties as any).signalGroup &&
                    sts.from <= now &&
                    sts.to > now
                );
                const messageId = [
                  (movementCenterline.properties as any).messageId,
                  (csts ?? { from: 0 }).from,
                ].join("_");

                return {
                  ...movementCenterline,
                  properties: {
                    ...movementCenterline.properties,
                    eventState: csts?.eventState ?? "unavailable",
                    messageId,
                    highlighted: hoveredObject === messageId,
                  },
                };
              })
              .filter(
                (movementCenterline) =>
                  movementCenterline.properties.eventState !==
                    "stop-And-Remain" || debug === "enabled"
              )
              .sort((a, b) =>
                a.properties.highlighted === b.properties.highlighted
                  ? 0
                  : a.properties.highlighted
                  ? 1
                  : -1
              ),
          }}
          movementSurfaces={{
            type: "FeatureCollection",
            features: dataState.messages.movementSurfaces
              .map((movementSurface) => {
                const csts = dataState.signalTypeStates.find(
                  (sts) =>
                    sts.signalGroup ===
                      (movementSurface.properties as any).signalGroup &&
                    sts.from <= now &&
                    sts.to > now
                );
                const messageId = [
                  (movementSurface.properties as any).messageId,
                  (csts ?? { from: 0 }).from,
                ].join("_");

                return {
                  ...movementSurface,
                  properties: {
                    ...movementSurface.properties,
                    cipt: csts?.cipt,
                    eventState: csts?.eventState ?? "unavailable",
                    messageId,
                    highlighted: hoveredObject === messageId,
                  },
                };
              })
              .filter(
                (movementSurface) =>
                  movementSurface.properties.eventState !== "stop-And-Remain" ||
                  debug === "enabled"
              )
              .sort((a, b) =>
                a.properties.highlighted === b.properties.highlighted
                  ? 0
                  : a.properties.highlighted
                  ? 1
                  : -1
              ),
          }}
          vehicleTelemetry={{
            type: "FeatureCollection",
            features: dataState.messages.vehicleTelemetry.map(
              (vehicleTelemetryMessage) => ({
                ...vehicleTelemetryMessage,
                properties: {
                  ...vehicleTelemetryMessage.properties,
                  age: parseFloat(
                    (now - vehicleTelemetryMessage.properties.time).toFixed(1)
                  ), // TODO: Reimplement this as a Mapbox filter
                  highlighted:
                    hoveredObject ===
                    (vehicleTelemetryMessage.properties as any).messageId,
                },
              })
            ),
          }}
          sites={Object.values(dataState.sites).map((site) => ({
            ...site,
            highlighted:
              currentDevice?.siteId === site.id || hoveredObject === site.id,
          }))}
        />
      </div>
    </>
  ) : (
    <NotFoundPage />
  );
};
export default TimeTravelPage;
