import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Chip } from "@mui/material";

import Loader from "../Loader";
import CardFieldset from "../containers/CardFieldset";
import CirrusButton from "../controls/Button";
import NotFoundPage from "../pages/NotFoundPage";
import {
  canAccessService,
  getRoleDescription,
  getRoleName,
} from "../../lib/auth";
import { useAuth } from "../../state/authState";
import { useUI } from "../../state/uiState";

import ArrowBackIcon from "../../assets/icons/arrowBack.svg";

import { User } from "../../lib/auth";
import { epochToDate, epochToTime } from "../../lib/formatting";

import { serviceBasePaths } from "../../config";

const UserProfilePage = ({
  loading,
  user,
}: {
  loading: boolean;
  user: User;
}) => {
  const { authStatus, currentUser, getDebug } = useAuth();
  const [searchParams] = useSearchParams();
  const { setOverlay } = useUI();

  const userParam = searchParams.get("u") ?? "";

  const canManageUsers = canAccessService(
    currentUser.authMetadata,
    "web_client:user_management"
  );
  const isSelf = userParam === currentUser.id;

  if (authStatus !== "ready" || loading) {
    return <Loader />;
  }

  if (!user || (!canManageUsers && !isSelf)) {
    return <NotFoundPage />;
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "calc(100vw - 80px)",
        height: "calc(100vh - 64px)",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: 118,
          marginBottom: 24,
        }}
      >
        <div style={{ height: 76 }}>
          {canAccessService(
            currentUser.authMetadata,
            "web_client:user_management"
          ) ? (
            <Link
              style={{ border: "none", padding: 0 }}
              to={serviceBasePaths["web_client:user_management"]}
            >
              <CirrusButton
                className="inline return"
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: 90,
                  minHeight: 28,
                  marginTop: 16,
                  marginBottom: 32,
                  marginLeft: -8,
                  fontSize: "16px",
                }}
                title="Return to list"
              >
                <img
                  src={ArrowBackIcon}
                  alt="Return to list"
                  width={16}
                  height={17}
                />{" "}
                <span style={{ marginLeft: 8 }}>Return</span>
              </CirrusButton>
            </Link>
          ) : null}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: 40,
          }}
        >
          <h2
            style={{
              fontSize: "34px",
              lineHeight: "34px",
            }}
          >
            User Profile
          </h2>
          <div>
            {canAccessService(
              currentUser.authMetadata,
              "web_client:user_management"
            ) ? (
              <Button
                disabled={
                  isSelf ||
                  user.authMetadata.user_permission.includes("users:admin")
                }
                onClick={() => setOverlay(`removeUser:${user.id}`)}
                startIcon={<DeleteIcon />}
                style={{
                  height: 40,
                  marginLeft: 12,
                }}
                variant="outlined"
              >
                Remove User
              </Button>
            ) : null}
            {isSelf ||
            canAccessService(
              currentUser.authMetadata,
              "web_client:user_management"
            ) ? (
              <Button
                disabled={
                  isSelf ||
                  user.authMetadata.user_permission.includes("users:admin")
                }
                onClick={() => setOverlay(`editUser:${user.id}`)}
                size="large"
                startIcon={<EditIcon />}
                style={{
                  height: 40,
                  marginLeft: 12,
                  border: "solid 1px transparent",
                }}
                variant="contained"
              >
                Edit User
              </Button>
            ) : null}
          </div>
        </div>
      </div>
      <div>
        <CardFieldset
          legend="Personal Information"
          fields={[
            [
              { label: "First Name", value: user.firstName },
              { label: "Last Name", value: user.lastName },
              { label: "Email Address", value: user.emailAddress },
            ],
            [
              {
                label: "Role",
                value: (
                  <Chip
                    color={
                      getRoleName(user.authMetadata) === "Administrator"
                        ? "info"
                        : "default"
                    }
                    label={getRoleName(user.authMetadata)}
                    size="small"
                  />
                ),
              },
              {
                label: "Last Login",
                value: user.lastLoginAt
                  ? `${epochToDate(user.lastLoginAt)} ${epochToTime(
                      user.lastLoginAt
                    )}`
                  : "Never",
              },
              {
                label: "Created At",
                value: `${epochToDate(user.createdAt)} ${epochToTime(
                  user.createdAt
                )}`,
              },
            ],
          ]}
        />
        {canAccessService(
          currentUser.authMetadata,
          "web_client:user_management"
        ) ? (
          <CardFieldset
            legend="System Access"
            fields={[
              [
                {
                  label: "Fixed Devices",
                  value: user.authMetadata.user_permission.includes(
                    "roadside_devices:admin"
                  )
                    ? "Has access"
                    : user.authMetadata.user_permission.includes(
                        "roadside_devices:read"
                      )
                    ? "Has non-sensitive access"
                    : "Cannot access",
                },
                {
                  label: "Vehicle Devices",
                  value: user.authMetadata.user_permission.includes(
                    "vehicle_devices:admin"
                  )
                    ? "Has access"
                    : user.authMetadata.user_permission.includes(
                        "vehicle_devices:read"
                      )
                    ? "Has non-sensitive access"
                    : "Cannot access",
                },
                {
                  label: "V2X Messages",
                  value: user.authMetadata.user_permission.includes(
                    "v2x_messages:admin"
                  )
                    ? "Has access"
                    : user.authMetadata.user_permission.includes(
                        "v2x_messages:read"
                      )
                    ? "Has non-sensitive access"
                    : "Cannot access",
                },
              ],
              [
                {
                  label: "Application Access",
                  value: getRoleDescription(
                    user.authMetadata,
                    getDebug() === "enabled",
                    "update"
                  ),
                },
              ],
            ]}
          />
        ) : null}
      </div>
    </div>
  );
};
export default UserProfilePage;
